import React, { Dispatch, SetStateAction } from "react";

import "./SingleGunnerDesktopComponent.css";

import MalfunctionOperation from "../../../../Shared/SPIKEGorilla/SingleGunner/MalfunctionOperation";
import SystemEncryption from "../../../../Shared/SPIKEGorilla/SingleGunner/SystemEncryption";
import TargetFeature from "../../../../Shared/SPIKEGorilla/SingleGunner/TargetProperties";
import ShootingExecutionDesktop from "../../../../Shared/SPIKEGorilla/SingleGunner/ShootingExecutionDesktop";
import HasifaTkifaTrainingTemplate from "../HasifaTkifaTrainingTemplate";
import { IShootingExecution } from "../../../../../Interfaces/Gorilla/IShootingExecution";
import { ISystemEncryption } from "../../../../../Interfaces/Gorilla/ISystemEncryption";
import { ITargetsPropertiesResults } from "../../../../../Interfaces/Gorilla/ITargetProperties";
import MainNotesTabDesktop from "../../../../Shared/SPIKEGorilla/Observer/MainNotesTabDesktop";
import {
  ESingleGunnerSteps,
  ISingleGunnerStep,
} from "../../../../../Interfaces/Gorilla/IHeatTemplate";
import { INote } from "../../../../../Interfaces/Gorilla/GorillaTrainings";
import { ELuncherTypes } from "../../../../../Enums/EHasifaTkifa";

interface IProps {
  malfunctionsOperation: IMalfunctionOperationOptions;
  shootingExecution: IShootingExecution;
  systemEncryption: ISystemEncryption;
  setMalfunctionOperation: Dispatch<
    SetStateAction<IMalfunctionOperationOptions>
  >;
  setShootingExecution: Dispatch<SetStateAction<IShootingExecution>>;
  setTargetsPropertiesResults: Dispatch<
    SetStateAction<ITargetsPropertiesResults>
  >;
  setSystemEncryption: Dispatch<SetStateAction<ISystemEncryption>>;
  targetsPropertiesResults: ITargetsPropertiesResults;
  setCurrentStepType: Dispatch<SetStateAction<ESingleGunnerSteps>>;
  currentStepType: ESingleGunnerSteps;
  saveHeat: () => void;
  mainNotes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  singleGunnerSteps: Record<ESingleGunnerSteps, ISingleGunnerStep>;
  isComponentCompleted: boolean;
  selectedLuncherType: ELuncherTypes;
}
const SingleGunnerDesktopComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    currentStepType,
    setCurrentStepType,
    malfunctionsOperation,
    systemEncryption,
    setMalfunctionOperation,
    setSystemEncryption,
    setShootingExecution,
    setTargetsPropertiesResults,
    shootingExecution,
    targetsPropertiesResults,
    saveHeat,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
    singleGunnerSteps,
    isComponentCompleted,
    selectedLuncherType,
  } = props;

  const stepsComponent: Record<ESingleGunnerSteps, () => JSX.Element> = {
    [ESingleGunnerSteps.SystemEncryption]: () => (
      <SystemEncryption
        setSystemEncryption={setSystemEncryption}
        systemEncryption={systemEncryption}
        selectedLuncherType={selectedLuncherType}
      />
    ),
    [ESingleGunnerSteps.TargetsProperties]: () => (
      <TargetFeature
        setTargetsPropertiesResults={setTargetsPropertiesResults}
        targetsPropertiesResults={targetsPropertiesResults}
      />
    ),
    [ESingleGunnerSteps.MalfunctionOperation]: () => (
      <MalfunctionOperation
        malfunctionsOperation={malfunctionsOperation}
        setMalfunctionOperation={setMalfunctionOperation}
      ></MalfunctionOperation>
    ),
    [ESingleGunnerSteps.ShootingExecution]: () => (
      <ShootingExecutionDesktop
        malfunctionsOperation={malfunctionsOperation}
        setMalfunctionOperation={setMalfunctionOperation}
        setShootingExecution={setShootingExecution}
        shootingExecution={shootingExecution}
        setSystemEncryption={setSystemEncryption}
        systemEncryption={systemEncryption}
      ></ShootingExecutionDesktop>
    ),
    [ESingleGunnerSteps.MainNotes]: () => (
      <MainNotesTabDesktop
        notes={mainNotes}
        deleteNote={deleteNote}
        editNote={editNote}
        addNote={addNote}
      ></MainNotesTabDesktop>
    ),
  };

  return (
    <HasifaTkifaTrainingTemplate
      component={stepsComponent[currentStepType]()}
      startButtonHandler={() => {
        setCurrentStepType((prev) => prev - 1);
      }}
      endButtonHandler={() => {
        currentStepType === Object.keys(singleGunnerSteps).length - 1
          ? saveHeat()
          : setCurrentStepType((prev) => prev + 1);
      }}
      endButtonText={
        currentStepType === Object.keys(singleGunnerSteps).length - 1
          ? "endHeat"
          : "next"
      }
      startButtonText="prev"
      startButtonIcon
      endButtonIcon={
        currentStepType < Object.keys(singleGunnerSteps).length - 1
      }
      isDisabledStartButton={currentStepType <= 0}
      isDisabledEndButton={
        (singleGunnerSteps[currentStepType]?.isMustCompleted &&
          !singleGunnerSteps[currentStepType].isCompleted) ||
        currentStepType > Object.keys(singleGunnerSteps).length - 1
      }
      isToDisplayWarning={
        singleGunnerSteps[currentStepType]?.isMustCompleted &&
        !singleGunnerSteps[currentStepType].isCompleted
      }
      headerTitle={
        singleGunnerSteps[currentStepType]?.description
          ? singleGunnerSteps[currentStepType]?.description
          : singleGunnerSteps[currentStepType]?.title
      }
      isComponentCompleted={isComponentCompleted}
    ></HasifaTkifaTrainingTemplate>
  );
};

export default SingleGunnerDesktopComponent;
