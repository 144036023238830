import React, { useContext, useEffect, useState } from "react";
import { IMobileDataPostingProps } from "../../../Interfaces/IMobileDataPostingProps";
import { IonCol, IonRow } from "@ionic/react";
import PMLabel from "../../../components/themeComponents/PMLabel";
import "./BilliardComponent.css";
import { sendDrillToBBackend } from "../../../components/Desktop/DataPosting/dataPostingHelpers";
import EEventType from "../../../Interfaces/EEventType";
import { useTranslation } from "react-i18next";
import customToast from "../../../components/Shared/Toast/CustomToast";
import NumberSelector from "../../../components/Shared/SPIKEGorilla/NumberSelector";
import ISimpleTrainee, { ETeam } from "../../../Interfaces/ISimpleTrainee";
import BilliardToggle from "../../../components/Shared/Buttons/BilliardToggle";
import EmptyState from "../../../components/Shared/EmptyState/EmptyState";
import { usePlanValuesForSelectedPlan } from "../../../components/CustomHooks/HooksWithRQ/usePlanValuesForSelectedPlan";
import { ElementsContext } from "../../../context/ElementsContext/ElementsContext";
const RemainingBallsElementName = "RemainingBalls";

interface IProps extends IMobileDataPostingProps {
  trainees: ISimpleTrainee[];
  trainingTypeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
}
const BilliardComponent: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const {
    isSendClicked,
    setIsSendDisable,
    trainees,
    planId,
    afterPostHandler,
    trainingTypeId,
  } = props;
  const [winnerTeam, setWinner] = useState<ETeam>();
  const [ballsLeft, setBallsLeft] = useState<number>(0);
  const { elements } = useContext(ElementsContext);
  const { plansValuesForSelectedPlan } = usePlanValuesForSelectedPlan(planId);
  const maximumRemainingBallsAmount = //what is the element lower threshold = maximum ball on tables
    plansValuesForSelectedPlan?.elementsPlanValues.find(
      (element) =>
        element.elementId ===
        elements.find((e) => e.name === RemainingBallsElementName)?.id
    )?.lowerThreshold;

  useEffect(() => {
    setIsSendDisable &&
      setIsSendDisable(
        !(
          trainees.filter((f) => f.team === ETeam.teamBlue).length > 0 &&
          trainees.filter((f) => f.team === ETeam.teamGreen).length > 0
        ) || !winnerTeam
      );
  }, [trainees, winnerTeam]);

  const postData = () => {
    const timeStamp: Date = new Date();
    const promises = trainees.map((trainee) => {
      const billiardDrill = {
        MessageType: EEventType.BILLIARD_MESSAGE_TYPE,
        TimeStamp: timeStamp,
        TrainingTypeID: trainingTypeId,
        SoldierID: trainee.id,
        DrillStartTime: timeStamp,
        DrillEndTime: timeStamp,
        Result: trainee.team === winnerTeam,
        Winners: trainees
          .filter((f) => f.team === winnerTeam)
          .map((trainee) => trainee.name)
          .join(", "),
        Losers: trainees
          .filter((f) => f.team !== winnerTeam)
          .map((trainee) => trainee.name)
          .join(", "),
        PlanId: planId,
        RemainingBalls: trainee.team === winnerTeam ? null : ballsLeft,
      };

      // Wrap sendDrillToBackend in a Promise
      return new Promise<void>((resolve, reject) => {
        sendDrillToBBackend(
          billiardDrill,
          () => resolve(),
          () => reject()
        );
      });
    });

    // Wait for all promises to complete
    Promise.all(promises)
      .then(() => {
        if (props.isDesktop) customToast.success(t("dataSendSuccess"));
        else customToast.success(t("dataSendSuccess"));
        afterPostHandler && afterPostHandler();
        setWinner(undefined);
        setBallsLeft(0);
      })
      .catch(() => {
        customToast.error(t("dataSendError"));
      });
  };

  useEffect(() => {
    if (isSendClicked) postData();
  }, [isSendClicked]);

  return (
    <div className={`billiardComponentWrap scrollM`}>
      {trainees.length ? (
        <IonCol className="billiardCol scrollM">
          <IonRow className="billiardRow">
            <PMLabel cssClass="billiardTitle" fontFamily="SemiBold">
              {`${t("whoWon")}?!`}
            </PMLabel>
          </IonRow>
          <IonRow className="billiardRow">
            <BilliardToggle
              firstOptionNames={trainees
                .filter((f) => f.team === ETeam.teamGreen)
                .map((t) => t.name)}
              secondOptionNames={trainees
                .filter((f) => f.team === ETeam.teamBlue)
                .map((t) => t.name)}
              firstOptionValue={ETeam.teamGreen}
              secondOptionValue={ETeam.teamBlue}
              setState={setWinner}
              state={winnerTeam}
              isDesktop={props.isDesktop}
            ></BilliardToggle>
          </IonRow>
          {winnerTeam && (
            <>
              <IonRow className="billiardLoserRow">
                <PMLabel
                  cssClass="billiardForceName"
                  fontSize={props.isDesktop ? "xxLarge" : "large"}
                  fontFamily="SemiBold"
                >
                  {`${t("ballsLeftTo")} ${trainees
                    .filter((f) => f.team !== winnerTeam)
                    .map((t) => t.name)
                    .join(` & `)} ${t("onTheTable")}`}
                </PMLabel>
              </IonRow>
              <IonRow className="billiardRow">
                <NumberSelector
                  number={ballsLeft}
                  setNumber={setBallsLeft}
                  maximumNumber={maximumRemainingBallsAmount || 0}
                  textSize="large"
                />
              </IonRow>
            </>
          )}
        </IonCol>
      ) : (
        <EmptyState text="noSelectedData" />
      )}
    </div>
  );
};

export default BilliardComponent;
