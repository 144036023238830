import { FC, useEffect, useState } from "react";
import "./HorizontalProgressBar.css";

type Props = {
  progress: number;
};

const HorizontalProgressBar: FC<Props> = (props: Props) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!props.progress) setProgress(0);
    const targetProgress = props.progress; // Change this to your desired target progress value
    const animationDuration = 5000; // Change this to your desired animation duration (in milliseconds)
    const interval = 100; // Change this to control the update frequency

    const step = (targetProgress / animationDuration) * interval;

    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + step;
        if (newProgress >= targetProgress) {
          clearInterval(intervalId);
          return isNaN(targetProgress) ? 0 : targetProgress;
        }
        return isNaN(newProgress) ? 0 : newProgress;
      });
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.progress]);

  return (
    <div className="progress-container">
      <div
        className={`progress-bar ${
          progress < 100 ? "progress-bar-moving" : ""
        }`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default HorizontalProgressBar;
