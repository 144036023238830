import {
  useRef,
  Dispatch,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import readExcelFile from "read-excel-file";
import {
  IIndicator,
  ITrainingType,
  IElement,
} from "../../Interfaces/ITrainingPlan";
import { UPDATE_PLAN } from "../../redux/actions/plansActions";
import {
  DELETE_INDICATOR,
  ADD_INDICATOR,
} from "../../redux/actions/trainingPlanActions";
import { ITrainingPlanReducer } from "../../redux/reducers/trainingPlan";
import { AppState } from "../../redux/store/plansStore";
import {
  getTrainingPlanValuesColumns,
  getTrainingPlanColumns,
} from "../../services/plansSharedFunctions";
import customToast from "../Shared/Toast/CustomToast";
import IPlan from "../../Interfaces/IPlan";
import { IPlansReducer } from "../../redux/reducers/plans";
import { IndicatorsContext } from "../../context/IndicatorsContext/IndicatorsContext";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";
import { ElementsContext } from "../../context/ElementsContext/ElementsContext";
import ELanguage from "../../Enums/ELanguage";

const useImportTrainingPlan = (
  plan: IPlan | undefined,
  importOK: boolean,
  setImportOk: (state: boolean) => void,
  planToEdit: number | undefined
) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const dispatch = useDispatch<Dispatch<any>>();
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );
  const selectedPlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).selectedPlan;

  // const { trainingPlanIndicators } = useTrainingPlanValues(props.plan.id);
  const { indicators } = useContext(IndicatorsContext);
  const { trainingTypes } = useContext(TrainingTypesContext);
  const { elements } = useContext(ElementsContext);

  const { i18n } = useTranslation();
  const [file, setFile] = useState<(string | null)[][]>();

  useEffect(() => {
    setImportOk(false);
    if (file) {
      if (validateFileStructure(file)) {
        const idIndex = 0;
        const indicatorsIndex = 1;
        const trainingTypIndex = 2;
        const dateIndex = 2;
        const elementIndex = 3;
        const weightIndex = 4;
        const thresholdIndex = 5;
        const lowerThresholdIndex = 6;
        const upperThresholdIndex = 7;
        const isForDashboardIndex = 8;

        let planName = file[2][1];
        let planLethality = file[2][thresholdIndex];
        let startDateStr = file[2][dateIndex]?.split("-")[0];
        let endDateStr = file[2][dateIndex]?.split("-")[1];
        let year = endDateStr?.split("/")[2];
        const startDate = new Date(
          String(
            `${startDateStr?.split("/")[1]}/${
              startDateStr?.split("/")[0]
            }/${year}`
          )
        );
        const endDate = new Date(
          String(
            `${endDateStr?.split("/")[1]}/${endDateStr?.split("/")[0]}/${year}`
          )
        );

        let trainingPlanDataWithIds = file.filter((row) => row[idIndex]);
        let indicatorsArray: IIndicator[] = [];
        let trainingTypesArray: ITrainingType[] = [];
        let elementsArray: IElement[] = [];

        trainingPlanDataWithIds.reverse().forEach((row, index) => {
          if (row[elementIndex]) {
            let element = elements.find(
              (element) => element.id === Number(row[idIndex])
            );
            if (element) {
              let elementObj: IElement = {
                elementId: element?.id,
                id: element.id,
                trainingTypeId: element.trainingTypeId,
                name: element?.name,
                requiredThreshold: Number(row[thresholdIndex]),
                upperThreshold: Number(row[upperThresholdIndex]),
                lowerThreshold: Number(row[lowerThresholdIndex]),
                isForDashboard: Boolean(row[isForDashboardIndex]),
                weight: Number(row[weightIndex]),
                indicatorId: 0,
                isArchived: element.isArchived,
                isOnlyForDashboard: element.isOnlyForDashboard,
                units: element.units,
                viewOnly: false,
                description: element.description,
              };
              elementsArray.push({ ...elementObj });
            }
          }

          if (row[trainingTypIndex]) {
            let trainingType = trainingTypes.find(
              (trainingType) => trainingType.id === Number(row[idIndex])
            );
            if (trainingType) {
              let trainingTypeObj: ITrainingType = {
                trainingTypeId: trainingType?.id,
                name: trainingType?.name,
                threshold: Number(row[thresholdIndex]),
                elements: elementsArray.map((element) => ({
                  ...element,
                  indicatorId: trainingType!.indicatorId,
                })),
                weight: Number(row[weightIndex]),
                indicatorId: trainingType.indicatorId,
              };
              trainingTypesArray.push({ ...trainingTypeObj });
            }
            elementsArray = [];
          }
          if (row[indicatorsIndex]) {
            let indicator = indicators.find(
              (indicator) => indicator.id === Number(row[idIndex])
            );
            if (indicator) {
              let indicatorObject: IIndicator = {
                indicatorId: indicator?.id,
                name: indicator?.name,
                threshold: Number(row[thresholdIndex]),
                trainingTypes: trainingTypesArray,
                weight: Number(row[weightIndex]),
              };
              indicatorsArray.push({ ...indicatorObject });
            }
            trainingTypesArray = [];
          }
        });
        indicatorsState.indicators?.forEach((indicator) => {
          dispatch({ type: DELETE_INDICATOR, id: indicator?.indicatorId });
        });

        setTimeout(() => {
          dispatch({
            type: UPDATE_PLAN,
            plan: {
              id: plan?.id,
              name: planName,
              startDate: !isNaN(startDate.getTime()) ? startDate : new Date(),
              endDate: !isNaN(endDate.getTime()) ? endDate : new Date(),
              lethality: Number(planLethality),
              isTest: plan?.isTest,
            },
          });
          indicatorsArray.forEach((indicator, index) => {
            dispatch({
              type: ADD_INDICATOR,
              indicator: {
                ...indicator,
              },
              indicatorRenderId: index + indicators.length,
            });
          });
        }, 150);
      } else {
        customToast.error(t("errorWhileImportPlan"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const validateFileStructure = (rows: (string | null)[][]): boolean => {
    return (
      rows[4].toString() ===
        getTrainingPlanValuesColumns()
          .map((col) => t(col))
          .toString() &&
      rows[1].filter((col) => !!col && col.length).toString() ===
        getTrainingPlanColumns()
          .filter((col) => !!col && col.length)
          .map((col) => t(col))
          .toString()
    );
  };

  useEffect(() => {
    if (importOK && (!planToEdit || selectedPlan?.id === planToEdit))
      ref.current?.click();
  }, [importOK, selectedPlan, planToEdit]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    readExcelFile(acceptedFiles[0]).then((rows: (string | null)[][]) => {
      setFile(rows);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onFileDialogCancel: () => {
      setImportOk(false);
    },
    onDrop,
    accept: { "application/vnd.ms-excel": [".xlsx"] },
  });
  return { getRootProps, getInputProps, ref };
};
export default useImportTrainingPlan;
