import { Dispatch } from "redux";
import IForceTreeNode from "./../../Interfaces/IForceTreeNode";
import { baseUrlPMBackend } from "../../Configurations/consts";
import axios from "../../Axios";
import customToast from "./../../components/Shared/Toast/CustomToast";
import { AppActions } from "./rootActions";
import { removeTempForce } from "../../services/treeSharedFunctions";
import { TFunction } from "i18next";
import IUser from "../../Interfaces/IUser";

export enum OrbatTreeActionTypes {
  GET_ORBAT_TREE = "GET_ORBAT_TREE",
  TREE_LOADING = "TREE_LOADING",
  TREE_END_LOADING = "TREE_END_LOADING",
  ADD_FORCE = "ADD_FORCE",
  ADD_OCCURED = "ADD_OCCURED",
  UPDATE_FORCE = "UPDATE_FORCE",
  UPDATE_OCCURED = "UPDATE_OCCURED",
  RESET_FLAGS = "RESET_FLAGS",
  DELETE_FORCE = "DELETE_FORCE",
  DELETE_OCCURED = "DELETE_OCCURED",
  REMOVE_UNSAVE_FORCE = "REMOVE_UNSAVE_FORCE",
}

export interface GetOrbatTreeAction {
  type: OrbatTreeActionTypes.GET_ORBAT_TREE;
  forces: IForceTreeNode;
}

export interface LadingTreeAction {
  type: OrbatTreeActionTypes.TREE_END_LOADING;
}

export interface LadingTreeEndAction {
  type: OrbatTreeActionTypes.TREE_LOADING;
}

export interface AddForceAction {
  type: OrbatTreeActionTypes.ADD_FORCE;
  res:
    | {
        data: any;
        status: string;
      }
    | {
        msg: string;
        status: string;
      };
}

export interface AddOccurred {
  type: OrbatTreeActionTypes.ADD_OCCURED;
}

export interface UpdateForceAction {
  type: OrbatTreeActionTypes.UPDATE_FORCE;
  res:
    | {
        data: any;
        status: string;
      }
    | {
        msg: string;
        status: string;
      };
}

export interface UpdateOccured {
  type: OrbatTreeActionTypes.UPDATE_OCCURED;
}

export interface RemoveErrors {
  type: OrbatTreeActionTypes.RESET_FLAGS;
}

export interface DeleteForceAction {
  type: OrbatTreeActionTypes.DELETE_FORCE;
  force: IForceTreeNode;
}

export interface DeleteOccured {
  type: OrbatTreeActionTypes.DELETE_OCCURED;
}

export interface RemoveUnsaveForceAction {
  type: OrbatTreeActionTypes.REMOVE_UNSAVE_FORCE;
}

export type Actions =
  | AddForceAction
  | UpdateForceAction
  | DeleteForceAction
  | GetOrbatTreeAction
  | LadingTreeAction
  | LadingTreeEndAction
  | RemoveErrors
  | UpdateOccured
  | AddOccurred
  | DeleteOccured
  | RemoveUnsaveForceAction;

export const getOrbatTree = (t: TFunction) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: OrbatTreeActionTypes.TREE_LOADING });
    try {
      let tree = await axios.get(`${baseUrlPMBackend}forces/getForcesTree`);
      dispatch({
        type: OrbatTreeActionTypes.GET_ORBAT_TREE,
        forces: tree.data ? tree.data : ({} as IForceTreeNode),
      });
    } catch (e) {
      customToast.error(t("orbatLoadError"));
    }
    dispatch({ type: OrbatTreeActionTypes.TREE_END_LOADING });
  };
};

export const updateForceInTree = (
  newForce: IForceTreeNode,
  allowRestore?: boolean
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await axios
      .post(`${baseUrlPMBackend}forces/updateForceById`, {
        forceId: newForce.id,
        soldier_id: newForce.soldier_id,
        parent_id: newForce.parent_id,
        name: newForce.name,
        weapon_id: newForce.weapon_id,
        weapon_type: newForce.weapon_type,
        weapon_sight: newForce.weapon_sight,
        tag_id: newForce.tag_id,
        weapon_sight_id: newForce.weapon_sight_id,
        force_type: newForce.force_type,
        personal_id: newForce.personal_id,
        magazine_id: newForce.magazine_id,
        laser_id: newForce.laser_id,
        head_sensor_id: newForce.head_sensor_id,
        allowRestore: allowRestore,
      })
      .then((res) => {
        dispatch({
          type: OrbatTreeActionTypes.UPDATE_FORCE,
          res: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OrbatTreeActionTypes.UPDATE_FORCE,
          res: error.response.data,
        });
      });
    dispatch({ type: OrbatTreeActionTypes.UPDATE_OCCURED });
  };
};

const changeForceTree = (
  oldForce: IForceTreeNode,
  newForce: IForceTreeNode
) => {
  if (newForce.idToUpdate && +newForce.idToUpdate === +oldForce.id) {
    let newTree: IForceTreeNode = {
      id: newForce.id,
      is_deleted: oldForce.is_deleted,
      level: oldForce.level,
      name: newForce.name,
      nodes: oldForce.nodes,
      parent_id: oldForce.parent_id,
      soldier_id: newForce.soldier_id,
      tag_id: newForce.tag_id,
      weapon_id: newForce.weapon_id,
      weapon_type: newForce.weapon_type,
      weapon_sight: newForce.weapon_sight,
      weapon_sight_id: newForce.weapon_sight_id,
      force_type: newForce.force_type,
      is_soldier: newForce.is_soldier,
      personal_id: newForce.personal_id,
      magazine_id: newForce.magazine_id,
      laser_id: newForce.laser_id,
      head_sensor_id: newForce.head_sensor_id,
    };
    Object.assign(oldForce, newTree);
  } else {
    oldForce.nodes &&
      oldForce.nodes.forEach((node: IForceTreeNode) => {
        changeForceTree(node, newForce);
      });
  }
};

export const changeNodeInTree = (
  oldForce: IForceTreeNode,
  newForce: IForceTreeNode = {} as IForceTreeNode,
  state: "add" | "update" | "delete" | "remove-unsave"
) => {
  let tree: IForceTreeNode = oldForce;
  if (state === "update") {
    changeForceTree(tree, newForce);
  } else if (state === "add") {
    removeTempForce(tree);
    tree && addChildToTree(tree, newForce);
  } else if (state === "delete") {
    removeItemFromTree(tree, newForce);
  } else if (state === "remove-unsave") {
    removeTempForce(tree);
  }

  return { ...tree };
};

export const resetFlags = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: OrbatTreeActionTypes.RESET_FLAGS });
  };
};

export const addForce = (force: IForceTreeNode, allowRestore: boolean) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await axios
      .post(`${baseUrlPMBackend}forces/addForce`, {
        allowRestore: allowRestore,
        soldier_id: force.soldier_id,
        name: force.name,
        forceId: force.parent_id,
        tag_id: force.tag_id,
        weapon_id: force.weapon_id,
        weapon_type: force.weapon_type,
        weapon_sight: force.weapon_sight,
        weapon_sight_id: force.weapon_sight_id,
        force_type: force.force_type,
        personal_id: force.personal_id,
        magazine_id: force.magazine_id,
        laser_id: force.laser_id,
        head_sensor_id: force.head_sensor_id,
      })
      .then((res) => {
        dispatch({ type: OrbatTreeActionTypes.ADD_FORCE, res: res.data });
      })
      .catch((res) => {
        dispatch({
          type: OrbatTreeActionTypes.ADD_FORCE,
          res: res.response?.data,
        });
      });
    dispatch({ type: OrbatTreeActionTypes.ADD_OCCURED });
  };
};

const addChildToTree = (tree: IForceTreeNode, force: IForceTreeNode): void => {
  if (tree.id === Number(force.parent_id)) {
    let newForce: IForceTreeNode = {
      ...force,
      id: Number(force.id),
      parent_id: Number(force.parent_id),
      soldier_id: force.soldier_id ? Number(force.soldier_id) : null,
      weapon_id: force.weapon_id,
      weapon_sight_id: Number(force.weapon_sight_id),
      magazine_id: force.magazine_id,
      laser_id: force.laser_id,
      head_sensor_id: force.head_sensor_id,
    };

    tree.nodes = tree.nodes ? [...tree.nodes, newForce] : [newForce];
  } else {
    tree.nodes &&
      tree.nodes.forEach((node: IForceTreeNode) => {
        addChildToTree(node, force);
      });
  }
};
export const resetBracelets = (
  force: IForceTreeNode,
  customToast: any,
  messageError: string
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await axios
      .post(`${baseUrlPMBackend}forces/resetBracelets`, {
        forceId: force.id,
      })
      .then((data) => {
        data.data.forEach((forceData: any) => {
          dispatch({
            type: OrbatTreeActionTypes.UPDATE_FORCE,
            res: { data: forceData, status: "OK" },
          });
        });

        dispatch({ type: OrbatTreeActionTypes.UPDATE_OCCURED });
      })
      .catch((error) => {
        customToast.error(messageError);
      });
  };
};
export const clearWeapons = (
  force: IForceTreeNode,
  customToast: any,
  messageError: string
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await axios
      .post(`${baseUrlPMBackend}forces/clearWeapons`, {
        forceId: force.id,
      })
      .then((data) => {
        data.data.forEach((forceData: any) => {
          dispatch({
            type: OrbatTreeActionTypes.UPDATE_FORCE,
            res: { data: forceData, status: "OK" },
          });
        });

        dispatch({ type: OrbatTreeActionTypes.UPDATE_OCCURED });
      })
      .catch((error) => {
        customToast.error(messageError);
      });
  };
};
export const deleteForce = (
  force: IForceTreeNode,
  customToast: any,
  messageCheckedInEroor: string,
  messageError: string,
  user: IUser,
  t: TFunction,
  setDeletingLoading?: (isLoading: boolean) => void
) => {
  return async (dispatch: Dispatch<AppActions | any>) => {
    //in case that some of the trainee are in site remove only the soldiers thet are not in site - the deleted soldiers.
    await axios
      .delete(`${baseUrlPMBackend}forces/deleteForce`, {
        params: {
          forceId: force.id,
        },
      })
      .then((data) => {
        //after delete the force fetch the orbat tree to update the display
        dispatch(getOrbatTree(t));

        dispatch({ type: OrbatTreeActionTypes.DELETE_OCCURED });
      })
      .catch((error) => {
        setDeletingLoading && setDeletingLoading(false);

        if (error?.response?.status === 405)
          customToast.error(messageCheckedInEroor);
        else {
          customToast.error(messageError);
        }
      });
  };
};

const removeItemFromTree = (
  tree: IForceTreeNode,
  itemToRemove: IForceTreeNode
) => {
  if (+tree.id === Number(itemToRemove.parent_id)) {
    if (tree.nodes) {
      let tempTree: IForceTreeNode = {
        ...tree,
        nodes: tree.nodes.filter(
          (node: IForceTreeNode) => node.id !== itemToRemove.id
        ),
      };

      Object.assign(tree, tempTree);
    }
  } else {
    tree.nodes &&
      tree.nodes.forEach((node: IForceTreeNode) => {
        removeItemFromTree(node, itemToRemove);
      });
  }
};

export const removeUnsaveForce = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: OrbatTreeActionTypes.REMOVE_UNSAVE_FORCE,
    });
  };
};
