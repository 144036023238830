import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { IForce } from "../../../Interfaces/results/force.interface";
import customToast from "../../Shared/Toast/CustomToast";

const fetchExportData = async (planId: number, forceId: number) => {
  return (
    await Axios.get(`${baseUrlPMBackend}performanceGrades/getReportGenerator`, {
      params: {
        planId: planId,
        forceId: forceId,
      },
    })
  ).data;
};

export const useReportGeneratorGrades = (planId?: number, forceId?: number) => {
  const { t } = useTranslation();

  let {
    data: dataResult,
    isError,
    isLoading,
  } = useQuery<IForce>({
    queryKey: ["reportGenerator", planId, forceId],
    queryFn: () => fetchExportData(planId!, forceId!),
    enabled: planId !== undefined && forceId !== undefined,
  });

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingData"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  return { dataResult, isLoading };
};
