import { useEffect, useState } from "react";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";

const useGetSubForces = (
  chosenForces: IForceTreeNode[],
  isSubordinates?: boolean
) => {
  const [subForces, setSubForces] = useState<IForceTreeNode[]>([]);

  const collectSubForces = (node: IForceTreeNode): IForceTreeNode[] => {
    if (!node) return [];

    const uniqueNodes: Map<number, IForceTreeNode> = new Map();
    const stack: IForceTreeNode[] = [node];

    while (stack.length > 0) {
      const currentNode = stack.pop();
      if (currentNode) {
        uniqueNodes.set(currentNode.id, currentNode);
        if (currentNode.nodes) {
          stack.push(...currentNode.nodes);
        }
      }
    }

    return Array.from(uniqueNodes.values());
  };

  const processForces = (
    forces: IForceTreeNode[],
    includeSubordinates?: boolean
  ) => {
    if (forces.length === 0) {
      setSubForces([]);
      return;
    }

    const uniqueForces = new Map<number, IForceTreeNode>(
      subForces.map((force) => [force.id, force])
    );
    const selectedForceIds = new Set(forces.map((force) => force.id));
    const forceToAdd = forces.find(
      (force) => !subForces.find((subForce) => subForce.id === force.id)
    );
    //insert all sub forces for checked forces
    if (forceToAdd) {
      {
        if (includeSubordinates) {
          const subordinates = collectSubForces(forceToAdd);
          subordinates.forEach((subordinate) => {
            uniqueForces.set(subordinate.id, subordinate);
          });
        } else uniqueForces.set(forceToAdd.id, forceToAdd);
      }
    }
    const forceToRemove = subForces.find(
      (subForce) => !selectedForceIds.has(subForce.id)
    );
    if (forceToRemove) {
      //if the force is unchecked do not insert its sub forces
      if (includeSubordinates) {
        const subordinates = collectSubForces(forceToRemove);
        subordinates.forEach((existingSubForce) => {
          uniqueForces.delete(existingSubForce.id);
        });
      } else uniqueForces.delete(forceToRemove.id);
    }
    setSubForces(Array.from(uniqueForces.values()));
  };

  useEffect(() => {
    processForces(chosenForces, isSubordinates);
  }, [chosenForces, isSubordinates]);

  return { subForces };
};

export default useGetSubForces;
