import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  ChartDataset,
} from "chart.js";
import { FC, useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/AppContext/AppContext";
import IDatasetsChart from "../../../../Interfaces/IDatasetsChart";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import ILabel from "../../../../Interfaces/ILabel";
import IPlan from "../../../../Interfaces/IPlan";
import IThreshold from "../../../../Interfaces/IThreshold";
import NotFoundPlan from "../../../../pages/Desktop/NotFound/NotFoundPlan";
import Spinner from "../../Spinner/Spinner";
import "./BarChart.css";
import DatalabelsPlugin from "chartjs-plugin-datalabels";
import { EGraphType } from "../../../../Enums/EGraphType";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { ITrainingPlanHashtagsResults } from "../../../../Interfaces/IHashtag";
import { BarOptions, DataLabelsOption, LegendOption } from "./BarConstOptions";
import EmptyState from "../../EmptyState/EmptyState";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  DatalabelsPlugin,
  AnnotationPlugin
);
interface IProps {
  labels?: ILabel[] | undefined;
  barChartData: ChartDataset<"bar">[];
  selectedForces?: (IForceFromOrbat | undefined)[] | undefined;
  id?: string;
  className?: string;
  color?: string;
  isDesktop?: boolean;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  plan: IPlan | undefined;
  onBarClick?: (elements: any) => void;
  lineOrScatterTypeToggle?: EGraphType;
  hashtagsThresholds?: IThreshold[];
  hashtagsForcesResults?: ITrainingPlanHashtagsResults[];
  isLoading?: boolean;
}

const HashtagsBarChart: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    labels,
    barChartData,
    isLoading,
    isDesktop,
    plan,
    onBarClick,
    lineOrScatterTypeToggle,
    hashtagsThresholds,
  } = props;
  const chartRef = useRef(null);

  const { t, i18n } = useTranslation();
  const [displayedHashtags, setDisplayedHashtags] = useState<Set<string>>(
    new Set()
  );

  const [loading, setLoading] = useState<boolean>(true);
  const { isAsDesktop } = useContext(AppContext);

  const isMobile: boolean = !isAsDesktop;
  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const clickHandler = (event: any, elements: any) => {
    onBarClick && onBarClick(elements);
  };

  function calculateSegmentWidth(ctx: any, labelsAmount: number) {
    const categoryWidth = 1 * categoryPercentage; // Adjusted by `categoryPercentage`
    const center = categoryWidth / labelsAmount / 2; //find the bar enter in indexes
    const barRatio =
      (labels?.length || 1) / (ctx.chart.width / barThickness) / 2; //get the half bar width in indexes position
    return {
      categoryWidth,
      center: center,
      barRatio,
    };
  }

  function indexToMin(
    ctx: any,
    index: number,
    annotationIndex: number,
    labelsAmount: number
  ) {
    const { categoryWidth, center, barRatio } = calculateSegmentWidth(
      ctx,
      labelsAmount
    );

    return (
      index +
      (annotationIndex * categoryWidth) / labelsAmount +
      -barRatio +
      center -
      categoryWidth / 2
    );
  }

  function indexToMax(
    ctx: any,
    index: number,
    annotationIndex: number,
    labelsAmount: number
  ) {
    const { categoryWidth, barRatio, center } = calculateSegmentWidth(
      ctx,
      labelsAmount
    );
    return (
      index +
      (annotationIndex * categoryWidth) / labelsAmount +
      barRatio +
      center -
      categoryWidth / 2
    );
  }

  // Generate 4 annotations for each label
  const getAnnotation = (
    categoryIndex: number,
    annotationIndex: number,
    yValue: number,
    labelsAmount: number
  ) => ({
    type: "line",
    borderColor: "white",
    borderWidth: 2,
    borderDash: [5, 1],
    xMin: (ctx: any) =>
      indexToMin(ctx, categoryIndex, annotationIndex, labelsAmount),
    xMax: (ctx: any) =>
      indexToMax(ctx, categoryIndex, annotationIndex, labelsAmount),
    xScaleID: "x",
    yMin: yValue,
    yMax: yValue,
    yScaleID: "y",
  });
  const [annotations, setAnnotations] = useState<any>({});

  useEffect(() => {
    setAnnotations(
      hashtagsThresholds
        ? hashtagsThresholds?.flatMap((label, labelIndex: number) => {
            const thresholds = label.thresholds?.filter((th) =>
              displayedHashtags.has(String(th.id))
            );
            return thresholds?.map((threshold: IThreshold, annotationIndex) => {
              return getAnnotation(
                labelIndex,
                annotationIndex,
                threshold.threshold || 0,
                thresholds?.length || 0
              );
            });
          })
        : {}
    );
  }, [hashtagsThresholds, displayedHashtags]);

  // Apply four annotations for each label
  const categoryPercentage = 0.8;
  const barPercentage = 0.7;
  const barThickness = isMobile ? 12 : 24;
  const options: ChartOptions<"bar"> = {
    ...BarOptions(
      categoryPercentage,
      barPercentage,
      barThickness,
      lineOrScatterTypeToggle,
      isMobile
    ),
    onClick: clickHandler,
    plugins: {
      annotation: {
        annotations: annotations,
      },
      legend: {
        ...LegendOption(isMobile, i18n.language),

        onClick: (e, legendItem, legend) => {
          // Call the default legend onClick behavior
          ChartJS.defaults.plugins.legend.onClick.call(
            legend,
            e,
            legendItem,
            legend
          );
          let legendId = barChartData[legendItem.datasetIndex || 0].label || "";
          setDisplayedHashtags((prev) => {
            let newHA = new Set(prev);
            if (legendItem.hidden) newHA.delete(legendId);
            else newHA.add(legendId);
            return newHA;
          });
        },
      },

      datalabels: {
        ...DataLabelsOption(isMobile),
        color: (context) => {
          let forceId = labels ? labels[context.dataIndex]?.id : undefined;
          let hashtagName: any = context.dataset.label;

          let forceData = props.hashtagsForcesResults?.find(
            (force) => Number(force.forceId) === Number(forceId)
          );
          if (forceData) {
            let trainingTypeData = Object.values(
              forceData.trainingTypeHashtagsResults
            ).flatMap((trainingType) => Object.keys(trainingType));
            if (trainingTypeData) {
              let hashtagData = trainingTypeData.includes(hashtagName);
              return hashtagData ? "#DDDDDD" : "red";
            }
          }
          return "red";
        },
      },
    },
  };

  useEffect(() => {
    if (barChartData) {
      const hiddenHashtags: string[] = [];
      if (chartRef.current) {
        const chartInstance: any = chartRef.current;
        chartInstance.data.datasets.forEach((dataset: any, index: number) => {
          const isHidden = chartInstance.getDatasetMeta(index).hidden;
          if (isHidden) hiddenHashtags.push(dataset.label);
        });
      }

      setDisplayedHashtags(
        new Set(
          barChartData
            ?.map((hashtag) => hashtag.label || "")
            .filter((id) => !hiddenHashtags.includes(id))
        )
      );
    }
  }, [barChartData, chartRef.current]);

  return (
    <div className={`bar-component ${props.className}`} id={props.id}>
      {loading ? (
        <Spinner />
      ) : labels?.length !== 0 ? (
        <div className="barChartWrap scrollM" style={{ height: "100%" }}>
          <div
            style={
              isDesktop
                ? {
                    minWidth: isDesktop
                      ? `${
                          Number(barChartData?.length) *
                          Number(labels?.length) *
                          barThickness *
                          2
                        }px`
                      : "100%",
                    height: "100%",
                  }
                : { width: "100%", height: "100%" }
            }
          >
            <Bar
              ref={chartRef}
              data={{
                labels: props.labels?.map((e) => e.label),
                datasets: barChartData,
              }}
              height={160}
              options={options}
            />
          </div>
        </div>
      ) : plan ? (
        <EmptyState text={t(isLoading ? "loadingData" : "noSelectedForces")} />
      ) : (
        <NotFoundPlan text={t("noActivePlanSelected")} />
      )}
    </div>
  );
};

export default HashtagsBarChart;
