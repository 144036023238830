import ESPIKEIcons from "../ESPIOKEIcons";
export enum ITargetsPropertiesOptions {
  MAN = "man",
  CAR = "car",
  WINDOW = "windows",
  TANGLED = "tangled",
  BUILT = "built",
  OPEN = "open",
  EXIST = "exist",
  NOT_EXIST = "not_exist",
  HIDDEN = "hidden",
  DRIVING = "driving",
  LOCKED = "locked",
  MEDIUM = "medium",
  LONG = "long",
  SHORT = "short",
  STATIC = "static",
  MOVING = "moving",
  DAY = "day",
  NIGHT = "night",
}
export interface ITargetsProperties {
  targetType: [
    { icon: ESPIKEIcons.MAN; name: ITargetsPropertiesOptions.MAN },
    { icon: ESPIKEIcons.CAR; name: ITargetsPropertiesOptions.CAR },
    { icon: ESPIKEIcons.WINDOW; name: ITargetsPropertiesOptions.WINDOW }
  ];
  areaZone: [
    { icon: ESPIKEIcons.COMPLEX; name: ITargetsPropertiesOptions.TANGLED },
    { icon: ESPIKEIcons.BUILT; name: ITargetsPropertiesOptions.BUILT },
    { icon: ESPIKEIcons.OPEN; name: ITargetsPropertiesOptions.OPEN }
  ];
  LOSToTarget: [
    { icon: ESPIKEIcons.EXIST; name: ITargetsPropertiesOptions.EXIST },
    { icon: ESPIKEIcons.NOT_EXIST; name: ITargetsPropertiesOptions.NOT_EXIST },
    { icon: ESPIKEIcons.HIDDEN; name: ITargetsPropertiesOptions.HIDDEN }
  ];
  shootingType: [
    { icon: ESPIKEIcons.DRIVING; name: ITargetsPropertiesOptions.DRIVING },
    { icon: ESPIKEIcons.LOCKED; name: ITargetsPropertiesOptions.LOCKED }
  ];
  shootToTarget: [
    { icon: ESPIKEIcons.SHORT; name: ITargetsPropertiesOptions.SHORT },
    { icon: ESPIKEIcons.MEDIUM; name: ITargetsPropertiesOptions.MEDIUM },
    { icon: ESPIKEIcons.LONG; name: ITargetsPropertiesOptions.LONG }
  ];
  targetState: [
    { icon: ESPIKEIcons.STATIC; name: ITargetsPropertiesOptions.STATIC },
    { icon: ESPIKEIcons.MOVING; name: ITargetsPropertiesOptions.MOVING }
  ];
  time: [
    { icon: ESPIKEIcons.DAY; name: ITargetsPropertiesOptions.DAY },
    { icon: ESPIKEIcons.NIGHT; name: ITargetsPropertiesOptions.NIGHT }
  ];
}

export interface ITargetsPropertiesResults {
  targetType:
    | ITargetsPropertiesOptions.MAN
    | ITargetsPropertiesOptions.CAR
    | ITargetsPropertiesOptions.WINDOW
    | undefined;
  areaZone:
    | ITargetsPropertiesOptions.TANGLED
    | ITargetsPropertiesOptions.BUILT
    | ITargetsPropertiesOptions.OPEN
    | undefined;
  LOSToTarget:
    | ITargetsPropertiesOptions.EXIST
    | ITargetsPropertiesOptions.NOT_EXIST
    | ITargetsPropertiesOptions.HIDDEN
    | undefined;
  shootingType:
    | ITargetsPropertiesOptions.DRIVING
    | ITargetsPropertiesOptions.LOCKED
    | undefined;
  shootToTarget:
    | ITargetsPropertiesOptions.SHORT
    | ITargetsPropertiesOptions.MEDIUM
    | ITargetsPropertiesOptions.LONG
    | undefined;
  targetState:
    | ITargetsPropertiesOptions.STATIC
    | ITargetsPropertiesOptions.MOVING
    | undefined;
  time:
    | ITargetsPropertiesOptions.DAY
    | ITargetsPropertiesOptions.NIGHT
    | undefined;
}
export interface ITargetPropertiesTemplate {
  id: number;
  name: string;
  targetProperties: ITargetsPropertiesResults;
}
