// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roundedIconButton {
  height: 52px;
  width: 52px;
  padding: 2px;
  margin-top: 20px;
  margin-inline-end: 20px;
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  background-color: #ffffff0e;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.4);
}
.desktopweb .roundedIconButton:hover {
  opacity: 0.7;
}
.disabled {
  pointer-events: none;
}
.roundedIconButton.orange {
  background-color: var(--ion-color-orange);
}
`, "",{"version":3,"sources":["webpack://./src/components/themeComponents/PMIconRoundedButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,8CAA8C;AAChD;AACA;EACE,YAAY;AACd;AACA;EACE,oBAAoB;AACtB;AACA;EACE,yCAAyC;AAC3C","sourcesContent":[".roundedIconButton {\n  height: 52px;\n  width: 52px;\n  padding: 2px;\n  margin-top: 20px;\n  margin-inline-end: 20px;\n  display: flex;\n  justify-self: flex-end;\n  align-self: flex-end;\n  justify-content: center;\n  align-items: center;\n  background-color: #ffffff0e;\n  border-radius: 50%;\n  box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.4);\n}\n.desktopweb .roundedIconButton:hover {\n  opacity: 0.7;\n}\n.disabled {\n  pointer-events: none;\n}\n.roundedIconButton.orange {\n  background-color: var(--ion-color-orange);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
