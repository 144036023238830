import { FC, SetStateAction, useEffect } from "react";
import "./SingleGunner.css";
import {
  ITargetsProperties,
  ITargetsPropertiesOptions,
  ITargetsPropertiesResults,
} from "../../../../Interfaces/Gorilla/ITargetProperties";
import ElementRow from "../ElementRow";
import ESPIKEIcons from "../../../../Interfaces/ESPIOKEIcons";
import { IonRow, IonCol } from "@ionic/react";
import PMButton from "../../../themeComponents/PMButton";
import PMDropdown from "../../PMDropdown/PMDropdown";
import { useTargetProperties } from "../../../CustomHooks/useTargetProperties";
import { useTranslation } from "react-i18next";

type IProps = {
  targetsPropertiesResults: ITargetsPropertiesResults;
  setTargetsPropertiesResults: (
    value: SetStateAction<ITargetsPropertiesResults>
  ) => void;
  isMobile?: boolean;
  setExtraButton?: (button: React.ReactNode) => void;
};
const targetProperties: ITargetsProperties = {
  targetType: [
    { icon: ESPIKEIcons.MAN, name: ITargetsPropertiesOptions.MAN },
    { icon: ESPIKEIcons.CAR, name: ITargetsPropertiesOptions.CAR },
    { icon: ESPIKEIcons.WINDOW, name: ITargetsPropertiesOptions.WINDOW },
  ],
  areaZone: [
    { icon: ESPIKEIcons.COMPLEX, name: ITargetsPropertiesOptions.TANGLED },
    { icon: ESPIKEIcons.BUILT, name: ITargetsPropertiesOptions.BUILT },
    { icon: ESPIKEIcons.OPEN, name: ITargetsPropertiesOptions.OPEN },
  ],
  LOSToTarget: [
    { icon: ESPIKEIcons.EXIST, name: ITargetsPropertiesOptions.EXIST },
    { icon: ESPIKEIcons.NOT_EXIST, name: ITargetsPropertiesOptions.NOT_EXIST },
    { icon: ESPIKEIcons.HIDDEN, name: ITargetsPropertiesOptions.HIDDEN },
  ],
  shootingType: [
    { icon: ESPIKEIcons.DRIVING, name: ITargetsPropertiesOptions.DRIVING },
    { icon: ESPIKEIcons.LOCKED, name: ITargetsPropertiesOptions.LOCKED },
  ],
  shootToTarget: [
    { icon: ESPIKEIcons.SHORT, name: ITargetsPropertiesOptions.SHORT },
    { icon: ESPIKEIcons.MEDIUM, name: ITargetsPropertiesOptions.MEDIUM },
    { icon: ESPIKEIcons.LONG, name: ITargetsPropertiesOptions.LONG },
  ],
  targetState: [
    { icon: ESPIKEIcons.STATIC, name: ITargetsPropertiesOptions.STATIC },
    { icon: ESPIKEIcons.MOVING, name: ITargetsPropertiesOptions.MOVING },
  ],
  time: [
    { icon: ESPIKEIcons.DAY, name: ITargetsPropertiesOptions.DAY },
    { icon: ESPIKEIcons.NIGHT, name: ITargetsPropertiesOptions.NIGHT },
  ],
};

const TargetFeature: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    targetsPropertiesResults,
    setTargetsPropertiesResults,
    setExtraButton,
  } = props;

  const { t } = useTranslation();
  const {
    templates,
    setSelectedTemplate,
    selectedTemplate,
    isSavePropertiesModalOpen,
    setIsSavePropertiesModalOpen,
    targetPropertiesSaveModal,
  } = useTargetProperties(
    targetsPropertiesResults,
    setTargetsPropertiesResults
  );
  useEffect(() => {
    if (setExtraButton) {
      setExtraButton(
        <PMButton
          size="large"
          color="orange"
          label={{ fontSize: "medium" }}
          fill="outline"
          cssClass="saveTargetPropertiesButton"
          onClickHandler={() => {
            setIsSavePropertiesModalOpen(true);
          }}
          isDisabled={Object.values(targetsPropertiesResults).includes(
            undefined
          )}
        >
          {t("saveTargetProperties")}
        </PMButton>
      );
    }
    return () => {
      setExtraButton && setExtraButton(undefined);
    };
  }, [setExtraButton, targetsPropertiesResults]);
  return (
    <IonRow className="targetFeatureRow">
      {isSavePropertiesModalOpen && targetPropertiesSaveModal}
      <IonCol className="targetFeatureContainer">
        <IonRow className="targetPropertiesDropdown">
          <PMDropdown
            options={templates}
            onOptionChanges={setSelectedTemplate}
            placeholder={t("selectFromSavedTargetProperties")}
            selectedOption={selectedTemplate}
          ></PMDropdown>
        </IonRow>

        <IonCol className="targetFeatureWrap scrollM">
          {Object.keys(targetProperties).map((key: string, index) => {
            const typedKey = key as keyof ITargetsPropertiesResults;
            const typedKeyIcon = key as keyof ITargetsProperties;

            return (
              <ElementRow
                key={index}
                elementName={key}
                iconsArray={targetProperties[typedKeyIcon]}
                result={targetsPropertiesResults[typedKey]}
                onSelect={(name: ITargetsPropertiesOptions) => {
                  setSelectedTemplate(undefined);
                  setTargetsPropertiesResults((prev) => ({
                    ...prev,
                    [typedKey]: name,
                  }));
                }}
                isMobile={props.isMobile}
              />
            );
          })}
        </IonCol>
      </IonCol>
    </IonRow>
  );
};

export default TargetFeature;
