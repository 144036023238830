import React, { useContext } from "react";
import { IonContent, IonItem, IonList, IonMenu } from "@ionic/react";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import { UserContext } from "../../../../context/UserContext/userContext";
import { routeRoles, routes } from "../../../../services/routeRoles";
import { useTranslation } from "react-i18next";
import { environment } from "../../../../Configurations/consts";
import PMIcon from "../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import EEnvironment from "../../../../Enums/EEnvironment";
import { AppModeContext } from "../../../../context/AppModeContext/AppModeContext";
import { EAppMode } from "../../../../Enums/EAppMode";

interface MenuItem {
  title: string;
  url: string;
  icon: string;
  requiredRoles: string[];
  allowedModes: string[];
}
export const getMenuRoles = (appMode: EAppMode, hasRelatedForce?: boolean) => {
  return new Map([
    [
      routes.trainingPlan,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [routes.orbatSettings, [...routeRoles.Admins, ...routeRoles.Instructors]],
    [
      routes.siteManagement,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [
      routes.performance,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [
      routes.dataHistory,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [
      routes.dataPosting,
      [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...(appMode === EAppMode.BILLIARD ? routeRoles.Viewers : []),
      ],
    ],
    [
      routes.reportGenerator,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [
      routes.personal,
      hasRelatedForce
        ? [
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]
        : [],
    ],
    [
      routes.stationView,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
    [
      routes.unauthorized,
      [...routeRoles.Admins, ...routeRoles.Instructors, ...routeRoles.Viewers],
    ],
  ]);
};
const Menu: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { appMode } = useContext(AppModeContext);
  const { user } = useContext(UserContext);
  const menuRoles = getMenuRoles(appMode, user.relatedForce !== undefined);

  const { t } = useTranslation();
  const MenuItems: MenuItem[] = [
    {
      title: t("PlansPage"),
      url: routes.trainingPlan,
      icon: "./assets/icon/folder.svg",
      requiredRoles: menuRoles.get(routes.trainingPlan) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.HASIFA_TKIFA,
        EAppMode.FIRE_SUPPORT,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("orbatTreePage"),
      url: routes.orbatSettings,
      icon: EIconsSrc.TREE_ICON,
      requiredRoles: menuRoles.get(routes.orbatSettings) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.HASIFA_TKIFA,
        EAppMode.FIRE_SUPPORT,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("siteManagement"),
      url: routes.siteManagement,
      icon: "./assets/icon/site-management.svg",
      requiredRoles: menuRoles.get(routes.siteManagement) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("competencyPage"),
      url: routes.performance,
      icon: "./assets/icon/bar_icon.svg",
      requiredRoles: menuRoles.get(routes.performance) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.HASIFA_TKIFA,
        EAppMode.FIRE_SUPPORT,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },

    {
      title: t("dataPosting"),
      url: routes.dataPosting,
      icon: EIconsSrc.DATA_POSTING,
      requiredRoles: menuRoles.get(routes.dataPosting) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.FIRE_SUPPORT,
        EAppMode.HASIFA_TKIFA,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("dataHistory"),
      url: routes.dataHistory,
      icon: EIconsSrc.HISTORY,
      requiredRoles: menuRoles.get(routes.dataHistory) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.HASIFA_TKIFA,
        EAppMode.FIRE_SUPPORT,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("reportGenerator"),
      url: routes.reportGenerator,
      icon: EIconsSrc.REPORT_GENERATOR,
      requiredRoles: menuRoles.get(routes.reportGenerator) || [],
      allowedModes: [
        EAppMode.WARTAC,
        EAppMode.SPIKE,
        EAppMode.HASIFA_TKIFA,
        EAppMode.FIRE_SUPPORT,
        EAppMode.OVERALL_COMPETENCY,
        EAppMode.BILLIARD,
      ],
    },
    {
      title: t("personalZone"),
      url: routes.personal,
      icon: EIconsSrc.PERSONAL_ZONE,
      requiredRoles: menuRoles.get(routes.personal) || [],
      allowedModes:
        environment === EEnvironment.staging
          ? [
              EAppMode.WARTAC,
              EAppMode.SPIKE,
              EAppMode.HASIFA_TKIFA,
              EAppMode.FIRE_SUPPORT,
              EAppMode.BILLIARD,
              EAppMode.OVERALL_COMPETENCY,
            ]
          : [
              EAppMode.WARTAC,
              EAppMode.FIRE_SUPPORT,
              EAppMode.OVERALL_COMPETENCY,
            ],
    },
    {
      title: t("stationView"),
      url: routes.stationView,
      icon: "./assets/icon/bar_icon.svg",
      requiredRoles: menuRoles.get(routes.stationView) || [],
      allowedModes: [EAppMode.SPIKE, EAppMode.HASIFA_TKIFA],
    },
  ];

  const menuItemsFilteredByEnv = () => {
    if (environment?.toString() === EEnvironment.production) {
      return MenuItems.filter(
        (item: MenuItem) =>
          ![
            routes.siteManagement.toString(),
            routes.stationView.toString(),
          ].includes(item.url)
      );
    } else {
      return MenuItems;
    }
  };

  return (
    <IonMenu contentId="main" className="side-menu">
      <IonContent scrollY={false} className="contentMenuList">
        <IonList className="menuList">
          {menuItemsFilteredByEnv().map((item: MenuItem, index: number) => {
            return (
              <IonItem
                hidden={
                  !item.requiredRoles.includes(user.role) ||
                  !item.allowedModes.includes(appMode)
                }
                class="ion-align-items-center"
                className={
                  location.pathname.includes(item.url)
                    ? "selected side-menu-item"
                    : "side-menu-item "
                }
                routerLink={item.url}
                routerDirection="none"
                lines="none"
                detail={false}
                key={index}
              >
                <div className="item-content">
                  <PMIcon
                    iconSrc={item.icon}
                    size="xLarge"
                    color={
                      location.pathname.includes(item.url) ? "xLight" : "light"
                    }
                  />
                  <p>{item.title}</p>
                </div>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
