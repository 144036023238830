import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SyncProgressBar.css";

import { baseUrlPMBackend } from "../../../Configurations/consts";
import PMLabel from "../../themeComponents/PMLabel";
import Axios from "../../../Axios";
import { SyncContext } from "../../../context/SyncContext/SyncContext";
import HorizontalProgressBar from "../../Shared/HorizontalProgressBar/HorizontalProgressBar";

const SyncProgressBar: React.FC = () => {
  const { t } = useTranslation();
  const { syncStarted, syncEnded, setSyncStarted, setSyncEnded } =
    useContext(SyncContext);
  const [progressInterval, setProgressInterval] = useState<NodeJS.Timeout>();

  const [progress, setProgress] = useState<number>(0);
  const [syncInprogress, setSyncInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (syncEnded) {
      setProgress(100);
      setSyncInProgress(false);
      progressInterval && clearInterval(progressInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncEnded]);

  useEffect(() => {
    if (syncStarted) {
      setSyncEnded(false);
      setProgress((prev) => (prev >= 100 ? 0 : prev));
      setSyncInProgress(true);
    } else {
      setSyncInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStarted]);

  useEffect(() => {
    if (syncStarted || syncInprogress) {
      let intervalId = setInterval(() => {
        progressInterval && clearInterval(progressInterval);

        Axios.get(`${baseUrlPMBackend}syncData/getProgress`)
          .then((res) => {
            setSyncStarted(true);
            console.log(res.data.progress);

            setProgress(res.data.progress);
            setSyncInProgress(true);
          })
          .catch((error) => {
            console.log(error);

            setSyncInProgress(false);
            setSyncStarted(false);
            clearInterval(intervalId);
          });
      }, 3000);
      setProgressInterval(intervalId);
    } else {
      progressInterval && clearInterval(progressInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStarted, syncInprogress]);

  useEffect(() => {
    setSyncInProgress(true);

    return () => {
      progressInterval && clearInterval(progressInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="syncProgressbar">
      {syncInprogress && syncStarted ? (
        <>
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t(`syncProgress`)}
          </PMLabel>
          <div className="progressbar">
            <HorizontalProgressBar progress={progress}></HorizontalProgressBar>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SyncProgressBar;
