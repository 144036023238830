import { FC } from "react";
import "./PMIconRoundedButton.css";
import { IPMIcon } from "../../Interfaces/IPMTheme";
import PMIcon from "./PMIcon";
const PMIconRoundedButton: FC<IPMIcon> = (props: IPMIcon) => {
  const { iconSrc, onClick, disabled } = props;
  return (
    <div
      className={`roundedIconButton ${disabled ? "disabled" : ""} ${
        props.color
      }`}
      onClick={onClick}
    >
      <PMIcon
        disabled={disabled}
        iconSrc={iconSrc}
        size={props.size}
        color={"light"}
      ></PMIcon>
    </div>
  );
};
export default PMIconRoundedButton;
