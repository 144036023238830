import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import MedicalComponent from "../../Shared/MenualStation/MedicalStation/MedicalComponent";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import "./MedicalDesktop.css";
import IDataPosting from "../../../Interfaces/IDataPosting";
const MedicalDesktop: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);
  const trainingTyeMedicalId = 12;
  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);
  const [isSendEnabled, setIsSendDisable] = useState<boolean>(false);

  useEffect(() => {
    setSendDataClicked(false);
  }, [isSendDataClicked]);
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"Medical_Care"}
      onClickThirdButton={() => {
        setSendDataClicked(true);
      }}
      isSendDisabled={isSendEnabled}
    >
      <IonGrid className="searchGrid">
        <IonRow className="searchRow">
          <IonCol size="2" className="searchCol">
            <SearchComponents
              soldierDetails={soldierDetails}
              setSoldierDetails={setSoldierDetails}
              isDesktop={true}
            />
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol size="9" className="col2Medical">
            <MedicalComponent
              traineeId={Number(soldierDetails[0]?.id)}
              trainingTypeId={trainingTyeMedicalId}
              isDesktop={true}
              isSendClicked={isSendDataClicked}
              planId={props.selectedPlan?.id}
              afterPostHandler={props.resetCompetencyForces}
              setIsSendDisable={setIsSendDisable}
            ></MedicalComponent>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default MedicalDesktop;
