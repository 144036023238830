import { useTargetPropertiesTemplated } from "./HooksWithRQ/useTargetPropertiesTemplates";
import Axios from "../../Axios";
import { useState, useEffect, SetStateAction } from "react";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IOption from "../../Interfaces/IOption";
import customToast from "../Shared/Toast/CustomToast";
import { ITargetsPropertiesResults } from "../../Interfaces/Gorilla/ITargetProperties";
import DragModal from "../Desktop/DragModal/DragModal";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import PMLabel from "../themeComponents/PMLabel";
import PMIcon from "../themeComponents/PMIcon";
import EIconsSrc from "../../Interfaces/EIconsSrc";
import PMInput from "../Desktop/TrainingPlan/PMInput";
import PMButton from "../themeComponents/PMButton";
import { useTranslation } from "react-i18next";

export const useTargetProperties = (
  targetsPropertiesResults: ITargetsPropertiesResults,
  setTargetsPropertiesResults: (
    value: SetStateAction<ITargetsPropertiesResults>
  ) => void
) => {
  const { t } = useTranslation();
  const { templates, refetch } = useTargetPropertiesTemplated();
  const [selectedTemplate, setSelectedTemplate] = useState<IOption>();
  const [templateName, setTemplateName] = useState<string>("");
  const [isSavePropertiesModalOpen, setIsSavePropertiesModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const selectedTemplateProperties = templates.find(
      (template) => template.id === selectedTemplate?.key
    )?.targetProperties;

    if (selectedTemplateProperties) {
      setTargetsPropertiesResults(selectedTemplateProperties);
    }
  }, [selectedTemplate]);
  /**
   * Save the target properties template
   */
  const saveTargetPropertiesTemplates = async () => {
    if (!Object.values(targetsPropertiesResults).includes(undefined)) {
      setIsSavePropertiesModalOpen(false);
      try {
        await Axios.post(`${baseUrlPMBackend}templates/saveTargetProperties`, {
          targetProperties: {
            ...targetsPropertiesResults,
            name: templateName,
          },
        });
        setTemplateName("");
        customToast.success(t("successSaveTemplate"));
        //refetch the saved properties after saving
        refetch();
      } catch (error: any) {
        if (error?.response?.data?.code === "23505")
          customToast.error(t("templateNameExists"));
        else customToast.error(t("failedSaveTemplate"));
      }
    } else customToast.error(t("spikeWarningText"));
  };
  // Modal for saving target properties
  const targetPropertiesSaveModal = (
    <DragModal
      isOpen={isSavePropertiesModalOpen}
      onDismiss={() => {
        setIsSavePropertiesModalOpen(false);
      }}
    >
      <IonGrid className="targetPropertiesSaveModal">
        <IonCol className="targetPropertiesSaveModalContent">
          <IonRow className="targetPropertiesSaveModalRow">
            <PMLabel fontColor="xLight" fontFamily="Regular">
              {t("savingTargetProperties")}
            </PMLabel>
            <PMIcon
              size="medium"
              iconSrc={EIconsSrc.CLOSE}
              onClick={() => setIsSavePropertiesModalOpen(false)}
            ></PMIcon>
          </IonRow>
          <IonRow className="targetPropertiesSaveModalRow">
            <PMInput
              inputName="propertiesName"
              inputType="text"
              placeholder={t("propertiesName")}
              inputValue={templateName}
              onChangeHandler={(_name, value) => {
                setTemplateName(value);
              }}
            ></PMInput>
          </IonRow>
          <IonRow className="targetPropertiesSaveModalRow saveButtonTargetProperties">
            <PMButton
              size="large"
              color="orange"
              onClickHandler={saveTargetPropertiesTemplates}
              label={{ fontSize: "medium" }}
              isDisabled={!templateName.length}
            >
              {t("save")}
            </PMButton>
          </IonRow>
        </IonCol>
      </IonGrid>
    </DragModal>
  );

  return {
    setSelectedTemplate,
    templates: templates.map((template) => ({
      value: template.name,
      key: template.id,
    })),
    selectedTemplate,
    templateName,
    setTemplateName,
    saveTargetPropertiesTemplates,
    setIsSavePropertiesModalOpen,
    isSavePropertiesModalOpen,
    targetPropertiesSaveModal,
  };
};
