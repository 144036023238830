import { TFunction } from "i18next";

const comparison = (
  currentValue: number,
  comparValue: number,
  big: boolean
) => {
  if (
    isNaN(currentValue) ||
    Number(currentValue) < 0 ||
    (!isNaN(Number(comparValue)) &&
      ((big && Number(comparValue) < Number(currentValue)) ||
        (!big && Number(comparValue) > Number(currentValue))))
  ) {
    return "invalidInput";
  }
  return "";
};
const comparisonFormatter = (
  key: string,
  comparisonKey: string,
  row: any,
  currentValue: any,
  t: TFunction,
  sumKeys?: string[]
) => {
  return comparison(
    sumKeys?.length
      ? [...sumKeys, key].reduce((sum, key) => sum + Number(row[key]), 0)
      : Number(currentValue),
    Number(row[comparisonKey]),
    true
  ) !== ""
    ? isNaN(Number(currentValue)) || Number(currentValue) < 0
      ? "positiveNumbersOnly"
      : `${t(comparisonKey)} < ${
          sumKeys?.length
            ? [...sumKeys, key].map((v) => getInitials(t(v)) || "").join(" + ")
            : t(key)
        }`
    : "";
};
const positiveNumber = (currentValue: number) => {
  if (isNaN(currentValue) || Number(currentValue) < 0) {
    //mark police cells as red
    return "positiveNumbersOnly";
  }
  return "";
};
const positiveNumberNtoM = (
  currentValue: string,
  max: number,
  min = 0,
  t: TFunction
) => {
  if (
    currentValue !== "" &&
    (isNaN(Number(currentValue)) ||
      Number(currentValue) > max ||
      Number(currentValue) < min)
  ) {
    //mark police cells as red
    return !isNaN(Number(currentValue)) && min !== 0
      ? `${t("mustBeBetween")} ${min} ${t("to")} ${max}`
      : `${t("positiveNumbersOnly")} < = ${max}`;
  }
  return "";
};

export const units = {
  Rounds: "rounds",
  Score: "score",
  Percentage: "percentage",
  Targets: "targets",
  Amount: "amount",
  Level: "level",
  Weight: "weight",
  Seconds: "seconds",
  Grade: "grade",
  Threshold: "threshold",
};

const validateInput = (input: string, unit: string) => {
  let error: string = "";
  let nInput: number = Number(input);

  switch (unit) {
    case units.Percentage:
    case units.Grade:
    case units.Weight:
    case units.Threshold:
      if (nInput < 0 || nInput > 100) error = "zeroHundredError";
      else error = "";
      break;

    case units.Score:
    case units.Amount:
    case units.Level:
    case units.Rounds:
    case units.Targets:
    case units.Seconds:
      if (nInput < 0) error = "positiveError";
      else error = "";
      break;
  }

  return error;
};

export {
  comparison,
  positiveNumber,
  positiveNumberNtoM,
  validateInput,
  comparisonFormatter,
};
const getInitials = (str: string): string => {
  const words = str.split(" ");
  const initials = words.map((word) => word[0].toUpperCase()).join(". ");
  return initials;
};
