// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pmIcon {
  color: var(--ion-color-fontLight);
  min-width: 18px;
}

.desktopweb .pmIcon:hover {
  opacity: 0.8;
}

.smallI {
  font-size: small !important;
}
.xSmallI {
  font-size: x-small !important;
}

.mediumI {
  font-size: medium !important;
}

.largeI {
  font-size: 20px !important;
}

.xLargeI {
  font-size: 28px !important;
}
.xxLargeI {
  font-size: 48px !important;
}
.xxxLargeI {
  font-size: 60px !important;
}
.xLight {
  color: var(--ion-color-fontXLight) !important;
}
.orange {
  color: var(--ion-color-orange) !important;
}
.light {
  color: var(--ion-color-fontLight) !important;
}

.dark {
  color: var(--ion-color-dark) !important;
}

.xDark {
  color: var(--ion-color-dark) !important;
}
.success {
  color: var(--ion-color-success) !important;
}
.tertiary {
  color: #20c6b9 !important;
}
.danger {
  color: var(--ion-color-danger) !important;
}
.disabledI {
  pointer-events: none !important;
}

.disabledColorI {
  color: var(--ion-color-disabled);
}

.pmIconButton {
  cursor: pointer;
  z-index: 2;
}

.pmIconButton:hover {
  color: var(--ion-color-fontXLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/themeComponents/PMIcon.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,6CAA6C;AAC/C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,4CAA4C;AAC9C;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".pmIcon {\n  color: var(--ion-color-fontLight);\n  min-width: 18px;\n}\n\n.desktopweb .pmIcon:hover {\n  opacity: 0.8;\n}\n\n.smallI {\n  font-size: small !important;\n}\n.xSmallI {\n  font-size: x-small !important;\n}\n\n.mediumI {\n  font-size: medium !important;\n}\n\n.largeI {\n  font-size: 20px !important;\n}\n\n.xLargeI {\n  font-size: 28px !important;\n}\n.xxLargeI {\n  font-size: 48px !important;\n}\n.xxxLargeI {\n  font-size: 60px !important;\n}\n.xLight {\n  color: var(--ion-color-fontXLight) !important;\n}\n.orange {\n  color: var(--ion-color-orange) !important;\n}\n.light {\n  color: var(--ion-color-fontLight) !important;\n}\n\n.dark {\n  color: var(--ion-color-dark) !important;\n}\n\n.xDark {\n  color: var(--ion-color-dark) !important;\n}\n.success {\n  color: var(--ion-color-success) !important;\n}\n.tertiary {\n  color: #20c6b9 !important;\n}\n.danger {\n  color: var(--ion-color-danger) !important;\n}\n.disabledI {\n  pointer-events: none !important;\n}\n\n.disabledColorI {\n  color: var(--ion-color-disabled);\n}\n\n.pmIconButton {\n  cursor: pointer;\n  z-index: 2;\n}\n\n.pmIconButton:hover {\n  color: var(--ion-color-fontXLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
