import { useEffect, Dispatch, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { billiardForceId } from "../../Configurations/consts";
import IForcePointer from "../../Interfaces/IForcePointer";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import { getOrbatTree } from "../../redux/actions/orbatTreeActions";
import { IOrbatTreeReducer } from "../../redux/reducers/orbatTree";
import { orbatTreeState } from "../../redux/store/OrbatTreeStore";
import { getForceById, treeFlatting } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const useGetBilliardTraineesIds = (isBilliard?: boolean) => {
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;
  const [billiardFlatTree, setFlatTree] = useState<IForcePointer[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();

  const [billiardForcesIds, setBilliardForcesIds] = useState<
    number[] | undefined
  >(isBilliard ? [] : undefined);

  useEffect(() => {
    //if its billiard get the billiard force and create billiard flat tree
    if (isBilliard && orbatTree && billiardForceId) {
      let force = getForceById(orbatTree, Number(billiardForceId));
      if (force) {
        setFlatTree([]);
        treeFlatting(force, setFlatTree);
      }
    }
  }, [orbatTree, isBilliard]);

  useEffect(() => {
    //set the soldiers id of the billiard flat tree
    if (isBilliard)
      setBilliardForcesIds(
        billiardFlatTree.filter((d) => d.is_soldier).map((f) => f.id)
      );
  }, [billiardFlatTree]);

  //fetch orbat tree first time
  useEffect(() => {
    if (!orbatTree) {
      dispatch(getOrbatTree(t));
    }
  }, [orbatTree]);
  return { billiardForcesIds };
};
export default useGetBilliardTraineesIds;
