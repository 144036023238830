import { useState, useEffect, useContext, Dispatch } from "react";
import IForceType from "../../Interfaces/IForceType";
import IIsDuplicatedForce from "../../Interfaces/IIsDuplicatedForce";
import { useDispatch, useSelector } from "react-redux";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import {
  addForce,
  getOrbatTree,
  OrbatTreeActionTypes,
  resetFlags,
  updateForceInTree,
} from "../../redux/actions/orbatTreeActions";
import { IOrbatTreeReducer } from "../../redux/reducers/orbatTree";
import { orbatTreeState } from "../../redux/store/OrbatTreeStore";
import customToast from "../Shared/Toast/CustomToast";
import IForcePointer from "../../Interfaces/IForcePointer";
import {
  flattenObject,
  getWeaponSight,
  getWeaponType,
  instanceOfIForceType,
  isValidAppMode,
  treeFlatting,
} from "../../services/helpers";
import { useTranslation } from "react-i18next";
import { ForcesContextDesktop } from "../../context/ForcesContext/forcesContextProviderDesktop";
import { AppModeContext } from "../../context/AppModeContext/AppModeContext";
import { EAppMode } from "../../Enums/EAppMode";
import { WeaponsContext } from "../../context/WeaponsContext/WeaponsContext";
/**
 * Custom hook for validating and add new force to orbat.
 * @param checkedForce the new created new force
 * @param setCheckedForce
 * @returns the validation forces and more
 */
const useValidateForce = (
  checkedForce: IForceTreeNode,
  setCheckedForce: React.Dispatch<React.SetStateAction<IForceTreeNode>>
) => {
  const { appMode } = useContext(AppModeContext);
  const isBilliardAppMode = () => isValidAppMode(appMode, EAppMode.BILLIARD);

  const { resetCompetencyForces } = useContext(ForcesContextDesktop);
  const [forceWithExistingTag, setForceWithExistingTag] =
    useState<IForceTreeNode>({} as IForceTreeNode);
  const [loading, setLoading] = useState<boolean>(false);
  const { weapons } = useContext(WeaponsContext);

  const [openRestoreAlert, setOpenRestoreAlert] = useState<{
    isOpen: boolean;
    operation:
      | OrbatTreeActionTypes.ADD_FORCE
      | OrbatTreeActionTypes.UPDATE_FORCE
      | undefined;
  }>({ isOpen: false, operation: undefined });
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;
  const [flatTree, setFlatTree] = useState<IForcePointer[]>([]);
  const [isDuplicated, setIsDuplicated] = useState<IIsDuplicatedForce>();
  const AddOccurred: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).AddOccurred;
  const addResStatus: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).addResStatus;
  const addErrorMsg: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).addErrorMsg;
  const checkForDuplicateValues = (
    field:
      | "personal_id"
      | "soldier_id"
      | "weapon_id"
      | "tag_id"
      | "magazine_id"
      | "laser_id"
      | "head_sensor_id",
    value: string | boolean | IForceType
  ): IIsDuplicatedForce => {
    let isDuplicatedSoldierField: boolean = false;
    let isDuplicatedPersonalIdGroup: boolean = false;
    if (value !== "") {
      let duplicateForces = flatTree.filter((soldier) => {
        const isSameForce = checkedForce.id !== soldier.id; // Ensure it's not the same force.

        switch (field) {
          case "personal_id":
            // Check if the personal ID or name matches but IDs are different.
            return (
              isSameForce && soldier[field]?.trim() === String(value).trim()
            );
          case "weapon_id":
            // Check if the weapon ID matches and is not zero but IDs are different.
            return (
              isSameForce && +value !== 0 && Number(soldier[field]) === +value
            );
          default:
            // Default case for numeric fields.
            return isSameForce && Number(soldier[field]) === +value;
        }
      });

      if (checkedForce.is_soldier) {
        if (duplicateForces.find((force) => force.soldier_id !== null)) {
          isDuplicatedSoldierField = true;
        }
      } else if (
        field === "personal_id" &&
        duplicateForces.find((force) => force.soldier_id === null)
      ) {
        isDuplicatedPersonalIdGroup = true;
      }
    }

    return {
      ...isDuplicated,
      isDuplicatedGroup: {
        ...isDuplicated!.isDuplicatedGroup,
        [field]: isDuplicatedPersonalIdGroup,
      },
      isDuplicatedSoldier: {
        ...isDuplicated!.isDuplicatedSoldier,
        [field]: isDuplicatedSoldierField,
      },
    };
  };

  const initIsDuplicated = (): void => {
    const initDuplicates = {
      isDuplicatedSoldier: {
        personal_id: false,
        soldier_id: false,
        tag_id: false,
        weapon_id: false,
        magazine_id: false,
        laser_id: false,
        head_sensor_id: false,
      },
      isDuplicatedGroup: { personal_id: false, name: false },
    };
    setIsDuplicated(initDuplicates);
  };

  const onChangeForceDetail = (
    field: string,
    value: string | boolean | IForceType
  ): void => {
    resetCompetencyForces();
    if (value !== null && value !== undefined) {
      if (typeof value === "object" && instanceOfIForceType(value)) {
        let changedForce: IForceTreeNode = {
          ...checkedForce,
          name:
            checkedForce.name === t("newForce") && !value.is_soldier
              ? t("newUnit")
              : checkedForce.name,
          is_soldier: value.is_soldier,
          force_type: value.name,
          soldier_id: value.is_soldier ? checkedForce.soldier_id : null,
          weapon_id: value.is_soldier ? checkedForce.weapon_id : null,
          weapon_type: value.is_soldier
            ? getWeaponType(weapons, checkedForce.weapon_type)
            : "None",
          weapon_sight: value.is_soldier
            ? getWeaponSight(weapons, checkedForce.weapon_sight)
            : "None",
          weapon_sight_id: value.is_soldier
            ? checkedForce.weapon_sight_id
            : null,
          tag_id: value.is_soldier ? checkedForce.tag_id : null,
          magazine_id: value.is_soldier ? checkedForce.magazine_id : null,
          laser_id: value.is_soldier ? checkedForce.laser_id : null,
          head_sensor_id: value.is_soldier ? checkedForce.head_sensor_id : null,
        };
        setCheckedForce(changedForce);
      } else {
        setCheckedForce((prev) => ({ ...prev, [field]: value }));
      }
      if (
        field === "personal_id" ||
        field === "soldier_id" ||
        field === "weapon_id" ||
        field === "tag_id" ||
        field === "magazine_id" ||
        field === "laser_id" ||
        field === "head_sensor_id"
      ) {
        setIsDuplicated(checkForDuplicateValues(field, value));
      }
    }
  };
  const saveValidation = () => {
    let valid = true;
    if (
      checkedForce.is_soldier &&
      (!checkedForce.soldier_id || +checkedForce.soldier_id === 0)
    ) {
      valid = false;
      customToast.error(t("soldierIdMissingAlert"));
    } else if (
      checkedForce.is_soldier &&
      !isBilliardAppMode() &&
      checkedForce.soldier_id?.toString().length !== 7
    ) {
      valid = false;
      customToast.error(t("soldierIdLengthAlert"));
    } else if (!!flattenObject(isDuplicated).find((item) => item === true)) {
      valid = false;
      customToast.error(t("duplicateDetails"));
    } else {
      if (
        checkedForce.is_soldier &&
        (checkedForce.weapon_type === "None" ||
          !checkedForce.weapon_type?.length ||
          !checkedForce.weapon_sight?.length ||
          checkedForce.weapon_sight === "None")
      ) {
        valid = false;
        customToast.error(t("weaponSiteAndWeaponTypeRequired"));
        return;
      }
    }
    if (!checkedForce.name.length) {
      valid = false;
      customToast.error(t("forceNameMissing"));
    }

    if (valid) {
      // If we'll want to re-enable only english and numbers
      //   else if (
      //     !/^[A-Za-z0-9]*$/.test(
      //       checkedForce.weapon_id ? checkedForce.weapon_id : ""
      //     )
      //   ) {
      //   valid = false;
      //   customToast.error(t("weaponIdEnglishOnlyAlert"));
      // }

      onSave();
    }
  };

  const actionOnSave = () => {
    setLoading(true);

    if (openRestoreAlert.operation === OrbatTreeActionTypes.ADD_FORCE) {
      dispatch(addForce(checkedForce, true));
    } else if (
      openRestoreAlert.operation === OrbatTreeActionTypes.UPDATE_FORCE
    ) {
      dispatch(updateForceInTree(checkedForce, true));
    }
  };
  const onSave = async () => {
    try {
      setLoading(true);
      if (checkedForce.id > 0) {
        if (forceWithExistingTag.id) {
          dispatch(
            updateForceInTree({
              ...forceWithExistingTag,
              tag_id: null,
              weapon_sight_id: checkedForce.weapon_sight_id,
              force_type: checkedForce.force_type,
              personal_id: checkedForce.personal_id,
              magazine_id: checkedForce.magazine_id,
              laser_id: checkedForce.laser_id,
              head_sensor_id: checkedForce.head_sensor_id,
            })
          );
        }
        dispatch(updateForceInTree(checkedForce));
      } else {
        dispatch(
          addForce(
            {
              ...checkedForce,
              personal_id: isBilliardAppMode()
                ? checkedForce.personal_id?.split("@")[0] ||
                  checkedForce.personal_id
                : checkedForce.personal_id,
            },
            false
          )
        );
      }
    } catch (e) {
      console.log(`Error in send changes. Error: ${e}`);
    }
  };

  useEffect(() => {
    if (orbatTree) {
      setFlatTree([]);
      treeFlatting(orbatTree, setFlatTree);
    }
  }, [orbatTree]);

  useEffect(() => {
    dispatch(getOrbatTree(t));
    initIsDuplicated();
  }, []);

  useEffect(() => {
    if (AddOccurred) {
      if (addResStatus === "OK") {
        customToast.success(t("forceAddedSuccessfullyMsg"));

        setCheckedForce({} as IForceTreeNode);
      } else if (addResStatus === "error") {
        if (
          addErrorMsg.includes("notNullMsg") ||
          addErrorMsg.includes("wrongLengthMsg")
        ) {
          if (addErrorMsg.includes("notNullMsg")) {
            customToast.error(
              `${t("notNullMsg1")} ${t(addErrorMsg.split("=")[1])} ${t(
                "notNullMsg2"
              )}`
            );
          }
          if (addErrorMsg.includes("wrongLengthMsg")) {
            customToast.error(
              `${t("wrongLengthMsg1")} ${t(addErrorMsg.split("=")[1])} ${t(
                "wrongLengthMsg2"
              )}`
            );
          }
        } else if (addErrorMsg.includes("soldierIdExist")) {
          let [isHeDeleted] = addErrorMsg.split("?").slice(1);

          if (isHeDeleted.split("=")[1] === "true") {
            setOpenRestoreAlert({
              isOpen: true,
              operation: OrbatTreeActionTypes.ADD_FORCE,
            });
          } else {
            customToast.error(t("traineeExistAndNotDeleted"));
          }
        }
      }

      setLoading(false);
      dispatch(resetFlags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddOccurred]);

  useEffect(() => {
    if (!checkedForce.is_soldier && isDuplicated) {
      setIsDuplicated((prev: IIsDuplicatedForce | undefined) => ({
        ...prev!,
        isDuplicatedSoldier: {
          personal_id: false,
          soldier_id: false,
          tag_id: false,
          weapon_id: false,
          magazine_id: false,
          laser_id: false,
          head_sensor_id: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedForce]);

  return {
    setOpenRestoreAlert,
    openRestoreAlert,
    onChangeForceDetail,
    isDuplicated,
    setForceWithExistingTag,
    actionOnSave,
    initIsDuplicated,
    saveValidation,
  };
};
export default useValidateForce;
