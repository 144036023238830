import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import axios, { CancelTokenSource } from "axios";
import IPlan from "../../../Interfaces/IPlan";

/**
 *
 * @returns array with of force types
 */

export const useGetRelevantPlans = (forcesIds: number[]) => {
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);
  const getRelevantPlans = async (forcesIds: number[]) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new request");
    }
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let res = await Axios.get(`${baseUrlPMBackend}plans/getRelevantPlans`, {
      params: {
        forcesIds: forcesIds,
      },
      cancelToken: source.token,
    });

    return res.data;
  };
  let { data, isError } = useQuery<number[]>({
    queryKey: ["getRelevantPlans", forcesIds],
    queryFn: () => getRelevantPlans(forcesIds),
    enabled: forcesIds.length > 0,
  });

  const relevantPlansIds = useMemo(() => data, [data]);
  useEffect(() => {
    if (isError) {
      console.log("error loading relevant plans");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    relevantPlansIds,
  };
};
