import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import InstructorFeedbackComponent from "../../Shared/MenualStation/InstructorFeedback/InstructorFeedbackComponent";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IDataPosting from "../../../Interfaces/IDataPosting";

import "./InstructorFeedback.css";
import SegmentsMenu from "../../../pages/Mobile/DataHistory/SegmentsMenu";
import { IPlanValues } from "../../../Interfaces/IPlanValues";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import Spinner from "../../Shared/Spinner/Spinner";
import InstructorFeedbackParamsHook from "../../CustomHooks/InstructorFeedbackParamsHook";

interface IProps extends IDataPosting {
  trainingTypeId: number;
  plansValuesForSelectedPlan: IPlanValues | undefined;
}

const InstructorFeedback: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);
  const {
    currentTrainingType,
    isLoading,
    isSendDataClicked,
    isSendEnabled,
    selectedSegment,
    setIsComplete,
    setSelectedSegment,
    setSendDataClicked,
    segmentsOptions,
  } = InstructorFeedbackParamsHook(
    props.plansValuesForSelectedPlan?.elementsPlanValues,
    props.trainingTypeId
  );
  const { t } = useTranslation();

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={currentTrainingType ? currentTrainingType.name : ""}
      onClickThirdButton={() => {
        setSendDataClicked(true);
      }}
      isSendDisabled={!isSendEnabled}
    >
      <IonGrid className="searchGridIF">
        <IonRow className="segmentRow">
          <SegmentsMenu
            segmentsOptions={segmentsOptions}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
          />
        </IonRow>

        <IonRow className="searchRow">
          {segmentsOptions.length ? (
            <>
              <IonCol size="2" className="searchCol">
                <SearchComponents
                  soldierDetails={soldierDetails}
                  setSoldierDetails={setSoldierDetails}
                  isDesktop={true}
                />
              </IonCol>
              <IonCol size="1" className="searchCol"></IonCol>

              <IonCol size="9" className="col2UIF">
                <InstructorFeedbackComponent
                  traineeId={Number(soldierDetails[0]?.id)}
                  isDesktop={true}
                  isSendClicked={isSendDataClicked}
                  setIsComplete={setIsComplete}
                  planId={props.selectedPlan?.id}
                  trainingTypeId={props.trainingTypeId}
                  elementsData={selectedSegment?.elements}
                  selectedSegmentTitle={selectedSegment?.title}
                  afterPostHandler={props.resetCompetencyForces}
                ></InstructorFeedbackComponent>
              </IonCol>
            </>
          ) : (
            <>
              {!isLoading ? (
                <PMLabel
                  fontColor="light"
                  fontFamily="ExtraLight"
                  fontSize="xxLarge"
                  textAlign="center"
                >
                  {t("noInstructorFeedbackInTrainingPlan")}
                </PMLabel>
              ) : (
                <Spinner className="instructorFeedbackSpinner"></Spinner>
              )}
            </>
          )}
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default InstructorFeedback;
