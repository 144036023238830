import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./SingleGunner.css";
import { IonPage } from "@ionic/react";

import SingleGunnerHeatsHook from "../../../CustomHooks/SingleGunnerHeatsHook";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import HeatsMenu from "../HeatsMenu";
import TrainingPlanRow from "../TrainingPlanRow";
import SingleGunnerComponent from "./SingleGunnerComponent";
import {
  EGorillaTrainings,
  IGorillaTrainingsForces,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import { mobileRoutes } from "../../../../services/routeRoles";
import useNavigation from "../../../CustomHooks/useNavigation";
import { ESingleGunnerSteps } from "../../../../Interfaces/Gorilla/IHeatTemplate";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";
import customToast from "../../Toast/CustomToast";

type IProps = {};

const SingleGunner: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  useMobileDataPostingParams();

  const { replace } = useNavigation();

  const {
    malfunctionsOperation,
    saveHeatAndMoveToNext,
    setMalfunctionOperation,
    setShootingExecution,
    setSystemEncryption,
    setTargetsPropertiesResults,
    shootingExecution,
    systemEncryption,
    targetsPropertiesResults,
    activeHeatId,
    mainNotes,
    resetHeat,
    addHeat,
    deleteHeat,
    addNote,
    setActiveHeat,
    endTrainingHandlerAfterConfirmation,
    setActiveHeatId,
    heatsArray,
    currentStepType,
    setCurrentStepType,
    gorillaTrainingsForces,
    traineeName,
    isModalOpen,
    setIsModalOpen,
    setSingleGunnerSteps,
    singleGunnerSteps,
    deleteNote,
    editNote,
    selectedLuncherType,
    isTrainingEnd,
  } = SingleGunnerHeatsHook();

  useEffect(() => {
    if (isTrainingEnd) {
      customToast.success(
        `${t("dataSendSuccess")} ${t("for")} ${t(
          EGorillaTrainings.SingleGunner
        )}`
      );
    }
  }, [isTrainingEnd]);

  useEffect(() => {
    const frameTrainingOptions = Object.keys(gorillaTrainingsForces).filter(
      (key) =>
        gorillaTrainingsForces[key as keyof IGorillaTrainingsForces] !==
        undefined
    );
    if (!frameTrainingOptions.length)
      replace(
        `${mobileRoutes.gorillaInitTraining}/${EGorillaTrainings.SingleGunner}`
      );
    // eslint-disable-next-line
  }, [gorillaTrainingsForces]);

  return (
    <>
      <HeatsMenu
        endTrainingHandler={endTrainingHandlerAfterConfirmation}
        heatsArray={heatsArray}
        activeHeatId={activeHeatId}
        setActiveHeat={setActiveHeat}
        addHeat={addHeat}
        deleteHeat={deleteHeat}
        resetHeat={(heatIndex) => {
          if (heatIndex === activeHeatId)
            setCurrentStepType(ESingleGunnerSteps.SystemEncryption);
          resetHeat(heatIndex);
        }}
        mainNotes={mainNotes}
        deleteNote={deleteNote}
        editNote={editNote}
        addNote={addNote}
        setActiveHeatId={setActiveHeatId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isTrainingEnd={isTrainingEnd}
      ></HeatsMenu>
      <IonPage>
        <div className="containerGorilla">
          <div className="headerGorilla">
            <MobileHeader />
            <TrainingPlanRow
              heatName={`${t("SingleGunner")} ${t("heat")} ${activeHeatId + 1}`}
              setIsModalOpen={setIsModalOpen}
            ></TrainingPlanRow>
          </div>
          {
            <SingleGunnerComponent
              traineeName={traineeName}
              currentStepType={currentStepType}
              setCurrentStepType={setCurrentStepType}
              activeHeatId={activeHeatId}
              malfunctionsOperation={malfunctionsOperation}
              saveHeat={saveHeatAndMoveToNext}
              setMalfunctionOperation={setMalfunctionOperation}
              setShootingExecution={setShootingExecution}
              setSystemEncryption={setSystemEncryption}
              setTargetsPropertiesResults={setTargetsPropertiesResults}
              shootingExecution={shootingExecution}
              systemEncryption={systemEncryption}
              targetsPropertiesResults={targetsPropertiesResults}
              isLastHeat={activeHeatId + 1 === heatsArray.length}
              setSingleGunnerSteps={setSingleGunnerSteps}
              singleGunnerSteps={singleGunnerSteps}
              selectedLuncherType={selectedLuncherType!}
            ></SingleGunnerComponent>
          }
        </div>
      </IonPage>
    </>
  );
};

export default SingleGunner;
