import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import customToast from "../../Shared/Toast/CustomToast";
import IElementType from "../../../Interfaces/IElementType";
import { ITargetPropertiesTemplate } from "../../../Interfaces/Gorilla/ITargetProperties";
/**
 *
 * @returns array with of target properties templates
 */
const asyncFetchTemplates = async () => {
  const targetPropertiesTemplates = await Axios.get(
    `${baseUrlPMBackend}templates/getTargetProperties`
  );
  return targetPropertiesTemplates.data;
};

export const useTargetPropertiesTemplated = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<ITargetPropertiesTemplate[]>([]);

  let query = useQuery<IElementType[]>({
    queryKey: ["targetPropertiesTemplates"],
    queryFn: asyncFetchTemplates,
    staleTime: 1000 * 60 * 5,
    enabled: true,
  });
  const { data, isLoading, isError, refetch } = query;

  useEffect(() => {
    if (!isLoading && !isError && data && data?.length) {
      //det the context with the elements
      setTemplates(
        data.map((template: any) => ({
          id: template.id,
          name: template.name,
          targetProperties: {
            ...template,
          },
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingTemplates"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  return { templates, refetch };
};
