// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundBlackPage {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 5;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  direction: ltr;
}
.backgroundBlackPage:lang(he) {
  direction: rtl;
}
.modal {
  position: relative;
  background-color: var(--ion-background-color);
  border-radius: 4px;
}

.trainingPlanModal {
  min-width: 900px;
  width: 70vw;
  height: 70vh;
}

.passwordModal {
  height: 300px;
  width: 500px;
}
.hashtagsModal {
  width: 95vw;
  height: 90vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DragModal/DragModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,UAAU;EACV,OAAO;EACP,MAAM;EACN,gBAAgB;EAChB,oCAAoC;EACpC,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".backgroundBlackPage {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  z-index: 5;\n  left: 0;\n  top: 0;\n  overflow: hidden;\n  background-color: rgba(0, 0, 0, 0.3);\n  direction: ltr;\n}\n.backgroundBlackPage:lang(he) {\n  direction: rtl;\n}\n.modal {\n  position: relative;\n  background-color: var(--ion-background-color);\n  border-radius: 4px;\n}\n\n.trainingPlanModal {\n  min-width: 900px;\n  width: 70vw;\n  height: 70vh;\n}\n\n.passwordModal {\n  height: 300px;\n  width: 500px;\n}\n.hashtagsModal {\n  width: 95vw;\n  height: 90vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
