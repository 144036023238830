import { FC } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./GeneralGrade.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";

interface IProps {
  setGrade: (grade: number | undefined) => void;
  elementName: string;
  grade: number | undefined;
  isDesktop?: boolean;
}

const GeneralGrade: FC<IProps> = (props: IProps) => {
  const { grade, setGrade, elementName } = props;
  const { t } = useTranslation();

  const gradesOptions: { garde: number; description: string; name: string }[] =
    [
      {
        description: "UnsatisfactoryDescription",
        name: "Unsatisfactory",
        garde: 1,
      },
      {
        description: "PartialDescription",
        name: "Partial",
        garde: 2,
      },
      { description: "FairDescription", name: "Fair", garde: 3 },
      { description: "GoodDescription", name: "Good", garde: 4 },
      { description: "ExcellentDescription", name: "Excellent", garde: 5 },
    ];
  return (
    <div className={`generalGradeDiv ${props.isDesktop ? "desktop" : ""}`}>
      <IonRow className="generalGradeTitleRow">
        <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
          {t(elementName)}
        </PMLabel>
      </IonRow>
      <IonRow className="generalGradeRow">
        {gradesOptions.map((val, index) => (
          <IonCol
            size={
              props.isDesktop
                ? String(12 / (gradesOptions.length || 1))
                : undefined
            }
            className={`gradeElement ${
              grade === val.garde ? `selectedGrade` : ""
            } `}
            key={index}
            onClick={() => {
              setGrade(grade === val.garde ? undefined : val.garde);
            }}
          >
            <div className={`gradeElementInner grade${val.garde}`}>
              <PMLabel
                textAlign="center"
                fontFamily="SemiBold"
                fontSize="medium"
                fontColor={"dark"}
              >
                {val.garde}
              </PMLabel>
              <PMLabel
                textAlign="center"
                fontFamily="Regular"
                fontSize="medium"
                fontColor={"dark"}
              >
                {t(val.name)}
              </PMLabel>
              <PMLabel
                textAlign="center"
                fontFamily="Regular"
                fontSize="small"
                fontColor={"dark"}
              >
                {t(val.description)}
              </PMLabel>
            </div>
          </IonCol>
        ))}
      </IonRow>
    </div>
  );
};

export default GeneralGrade;
