import {
  EDIT_ELEMENT,
  SAVE_INDICATORS_REQUEST,
  UPDATE_INDICATOR,
  SAVE_INDICATORS_SUCCESS,
  SAVE_INDICATORS_FAILURE,
  IndicatorActionTypes,
  FETCH_INDICATORS_SUCCESS,
  SET_INDICATOR,
  CANCEL_CHANGES,
} from "../actions/trainingPlanActions";
import {
  ADD_INDICATOR,
  DELETE_INDICATOR,
  EDIT_INDICATOR,
  EDIT_TRAINING_TYPE,
} from "../actions/trainingPlanActions";
import { IIndicator } from "../../Interfaces/ITrainingPlan";

export interface ITrainingPlanReducer {
  indicators: IIndicator[];
  loading: boolean;
  error: Error | "";
  indicatorRenderId: number;
  initIndicators: IIndicator[];
}

const indicatorReducerDefaultState: ITrainingPlanReducer = {
  indicators: [],
  loading: false,
  error: "",
  indicatorRenderId: 0,
  initIndicators: [],
};

const trainingPlanReducer = (
  state = indicatorReducerDefaultState,
  action: IndicatorActionTypes
) => {
  switch (action.type) {
    // Sets indicators
    case EDIT_TRAINING_TYPE:
      return {
        ...state,
        indicators: [
          ...state.indicators.map((oldIndicator) =>
            action.trainingType.indicatorId === oldIndicator.indicatorId
              ? {
                  ...oldIndicator,
                  trainingTypes: oldIndicator?.trainingTypes?.map(
                    (oldTrainingType) =>
                      action.trainingType.trainingTypeId ===
                      oldTrainingType.trainingTypeId
                        ? {
                            ...oldTrainingType,
                            weight: action.trainingType.weight,
                            threshold: action.trainingType.threshold,
                          }
                        : oldTrainingType
                  ),
                }
              : { ...oldIndicator }
          ),
        ],
      };
    case EDIT_ELEMENT:
      return {
        ...state,
        indicators: [
          ...state.indicators.map((oldIndicator) =>
            action.element.indicatorId === oldIndicator.indicatorId
              ? {
                  ...oldIndicator,
                  trainingTypes: oldIndicator.trainingTypes?.map(
                    (oldTrainingType) =>
                      action.element.trainingTypeId ===
                      oldTrainingType.trainingTypeId
                        ? {
                            ...oldTrainingType,
                            elements: oldTrainingType.elements.map(
                              (oldElement) =>
                                oldElement.elementId ===
                                action.element.elementId
                                  ? {
                                      ...oldElement,
                                      isForDashboard:
                                        action.element.isForDashboard,
                                      upperThreshold:
                                        action.element.upperThreshold,
                                      lowerThreshold:
                                        action.element.lowerThreshold,
                                      requiredThreshold:
                                        action.element.requiredThreshold,
                                      weight: action.element.weight,
                                    }
                                  : oldElement
                            ),
                          }
                        : oldTrainingType
                  ),
                }
              : { ...oldIndicator }
          ),
        ],
      };
    case FETCH_INDICATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        indicators: action.indicators,
        initIndicators: action.initIndicators
          ? action.initIndicators
          : state.initIndicators,
        error: "",
      };

    // Adds an indicator
    case ADD_INDICATOR:
      return {
        ...state,
        indicators: [...state.indicators, action.indicator],
        indicatorRenderId: action.indicatorRenderId
          ? action.indicatorRenderId
          : state.indicatorRenderId,
      };

    // Edit specific indicator
    case EDIT_INDICATOR:
      return {
        ...state,
        indicators: state.indicators
          ? [
              ...state.indicators?.map((oldIndicator) =>
                action.indicator.indicatorId === oldIndicator.indicatorId
                  ? {
                      ...oldIndicator,
                      name: action.indicator.name,
                      indicatorId: action.indicator.indicatorId,
                      weight: action.indicator.weight,
                      threshold: action.indicator.threshold,
                    }
                  : { ...oldIndicator }
              ),
            ]
          : [],
      };
    case SET_INDICATOR:
      return {
        ...state,
        indicators: state.indicators
          ? [
              ...state.indicators?.map((oldIndicator) =>
                oldIndicator.indicatorId === action.idToUpdate
                  ? action.indicator
                  : oldIndicator
              ),
            ]
          : [],
      };
    case DELETE_INDICATOR:
      return {
        ...state,
        indicators: [
          ...state.indicators.filter(
            ({ indicatorId }) => indicatorId !== action.id
          ),
        ].map((indicator, index) => {
          if (indicator.indicatorId) {
            return { ...indicator, indicatorRenderId: index + 1 };
          } else {
            return indicator;
          }
        }),
      };

    case UPDATE_INDICATOR: {
      return {
        ...state,
        indicators: [
          ...state.indicators.map((indicator) => {
            if (indicator.indicatorId === action.indicator.indicatorId) {
              return { ...indicator, ...action.indicator };
            } else {
              return indicator;
            }
          }),
        ],
      };
    }

    case CANCEL_CHANGES: {
      // init all indicators state
      return {
        ...state,
        indicators: state.initIndicators.map((i) => ({
          ...i,
          trainingTypes: i.trainingTypes.map((t) => ({
            ...t,
            elements: t.elements.map((e) => ({ ...e })),
          })),
        })),
        initIndicators: state.initIndicators.map((i) => ({
          ...i,
          trainingTypes: i.trainingTypes.map((t) => ({
            ...t,
            elements: t.elements.map((e) => ({ ...e })),
          })),
        })),
      };
    }
    case SAVE_INDICATORS_REQUEST: {
      return { ...state };
    }
    case SAVE_INDICATORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        initIndicators: action.indicators,
      };
    }

    case SAVE_INDICATORS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    default:
      return state;
  }
};

export default trainingPlanReducer;
