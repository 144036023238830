// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutPopover {
  display: flex;
  width: fit-content;
  justify-content: center;
  padding: 8px;
  direction: ltr;
}
.aboutPopover:lang(he) {
  direction: rtl;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/About/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".aboutPopover {\n  display: flex;\n  width: fit-content;\n  justify-content: center;\n  padding: 8px;\n  direction: ltr;\n}\n.aboutPopover:lang(he) {\n  direction: rtl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
