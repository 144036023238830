import { FC, useEffect, useState } from "react";
import "./StationAccordionItem.css";
import { IonItem } from "@ionic/react";
import PMLabel from "../../../../components/themeComponents/PMLabel";
import ITraineeDetails from "../../../../Interfaces/ITraineeDetails";
import PMIcon from "../../../../components/themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import EEventType from "../../../../Interfaces/EEventType";
import IPMCheckOut from "../../../../Interfaces/IPMCheckOut";
import { useTranslation } from "react-i18next";
import WarningModal from "../../../../components/Shared/SPIKEGorilla/WarningModal";
import customToast from "../../../../components/Shared/Toast/CustomToast";
interface IProps {
  traineeDetails: ITraineeDetails;
}
const StationAccordionItem: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [isNewTrainee, setNewTrainee] = useState<boolean>(false);
  const [isCheckout, setIsCheckoutAlertOpen] = useState<boolean>(false);
  const sendPMCheckouts = () => {
    if (props.traineeDetails.laneId !== null) {
      const laneCheckOut: IPMCheckOut = {
        MessageType: EEventType.PM_CHECKOUT,
        SoldierID: props.traineeDetails?.traineeId,
        LaneID: String(props.traineeDetails?.laneId),
        TimeStamp: new Date(),
        ForceId: props.traineeDetails?.uniqId,
      };
      Axios.post(
        `${baseUrlPMBackend}checkInOutOperation/PMCheckout`,
        laneCheckOut
      )
        .then((res) => {
          customToast.success(
            `${t("checkoutSucceed")} ${props.traineeDetails.traineeName}`
          );
        })
        .catch((error) => {
          customToast.error(`${t("checkoutFailed")}`);
          console.error(error);
        });
    }
  };
  useEffect(() => {
    if (
      props.traineeDetails.newInColumn &&
      props.traineeDetails.uniqId !== -1
    ) {
      setNewTrainee(true);
      let interval = setInterval(() => {
        setNewTrainee(false);
        clearInterval(interval);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.traineeDetails]);
  return (
    <IonItem color="" lines="none" className="stationAccordionTraineeItem">
      <PMLabel
        fontSize="large"
        fontColor={isNewTrainee ? "success" : "light"}
        fontFamily="Regular"
      >
        {`${props.traineeDetails.traineeName}`}
      </PMLabel>
      <PMIcon
        iconSrc={EIconsSrc.TRASH_ICON}
        size="medium"
        onClick={() => setIsCheckoutAlertOpen(true)}
      ></PMIcon>
      <WarningModal
        isOpen={isCheckout}
        acceptText={t("yes")}
        declineText={t("no")}
        onAccept={() => {
          sendPMCheckouts();
          setIsCheckoutAlertOpen(false);
        }}
        onDecline={() => {
          setIsCheckoutAlertOpen(false);
        }}
        title=""
        warningQuestion={`${t("deleteQues")} ${
          props.traineeDetails.traineeName
        } ${t("fromTheStation")}?`}
      ></WarningModal>
    </IonItem>
  );
};
export default StationAccordionItem;
