import React, { useState } from "react";
import { IonRadio, IonRadioGroup } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./LanguageChangePopover.css";
import PopoverItem from "../../themeComponents/PopoverItem";
import PopoverMenu from "../Popover/PopoverMenu";
import ELanguage from "../../../Enums/ELanguage";
import Axios from "../../../Axios";
interface IProps {
  isMobile?: boolean;
}
const LanguageChangePopover: React.FC<IProps> = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const languages = Object.keys(i18n.services.resourceStore.data);
  const isToDisplayPopover = languages.length > 2;
  const changeLanguage = (language: ELanguage) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    Axios.defaults.headers.common["language"] = language;
  };
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
  }>({
    showPopover: false,
    event: undefined,
  });

  const LanguageSelectionMobile = () => {
    return (
      <>
        {languages.map((option) => (
          <PopoverItem
            key={option}
            onClickHandler={(e: any) => {
              i18n.changeLanguage(option);
              localStorage.setItem("language", option);
            }}
          >
            <PMLabel
              textAlign="start"
              fontColor={i18n.language === option ? "orange" : "light"}
              fontFamily={i18n.language === option ? "Bold" : "Regular"}
              fontSize="medium"
            >
              {t(option)}
            </PMLabel>
          </PopoverItem>
        ))}
      </>
    );
  };
  return props.isMobile ? (
    LanguageSelectionMobile()
  ) : (
    <PopoverItem
      fontColor="light"
      fontFamily="Light"
      fontSize="medium"
      background="Blight"
      onClickHandler={
        isToDisplayPopover
          ? undefined
          : () => {
              changeLanguage(
                i18n.language === ELanguage.he ? ELanguage.en : ELanguage.he
              );
            }
      }
    >
      {isToDisplayPopover ? (
        <>
          <PMLabel
            onClick={(e) =>
              setShowPopover({
                showPopover: true,
                event: e.nativeEvent,
              })
            }
            textAlign="center"
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            cssClass="modeSelectionWrap"
          >
            {"Language"}
          </PMLabel>
          <PopoverMenu
            className="appModesPopover"
            popoverState={popoverState}
            onDismiss={() =>
              setShowPopover({
                showPopover: false,
                event: undefined,
              })
            }
          >
            <LanguageGroup isPopover changeLanguage={changeLanguage} />
          </PopoverMenu>
        </>
      ) : (
        <PMLabel
          textAlign="center"
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
        >
          {t(i18n.language === languages[0] ? languages[1] : languages[0])}
        </PMLabel>
      )}
    </PopoverItem>
  );
};
interface ILanguageGroupProps {
  isPopover?: boolean;
  onSelectHandler?: () => void;
  changeLanguage: (language: ELanguage) => void;
}
const LanguageGroup: React.FC<ILanguageGroupProps> = (
  props: ILanguageGroupProps
) => {
  const { t, i18n } = useTranslation();

  const languages = Object.keys(i18n.services.resourceStore.data);

  return (
    <IonRadioGroup value={i18n.language}>
      {languages.map((language) => (
        <PopoverItem
          key={language}
          onClickHandler={(e: any) => {
            props.changeLanguage(language as ELanguage);
          }}
        >
          <PMLabel
            textAlign="center"
            fontColor={language === i18n.language ? "orange" : "light"}
            fontFamily={language === i18n.language ? "Bold" : "Regular"}
            fontSize="medium"
          >
            {t(language)}
          </PMLabel>
          {props.isPopover ? (
            <IonRadio
              className="radioLanguages"
              slot="end"
              value={language}
            ></IonRadio>
          ) : null}
        </PopoverItem>
      ))}
    </IonRadioGroup>
  );
};

export { LanguageChangePopover, LanguageGroup };
