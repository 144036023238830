// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-add-new-force {
  --background: var(--ion-color-enable);
  --border-radius: 20px;
  direction: ltr;
}
.modal-add-new-force :lang(he) {
  direction: rtl;
}
.addNewForceGrid {
  width: 100%;
  padding: 24px;
  padding-top: 0;
}
.addNewForceRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 24px;
}
.addForceIconRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.addNewForceButton {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/SearchSoldierComp/AddNewForceModal.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,aAAa;EACb,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb","sourcesContent":[".modal-add-new-force {\n  --background: var(--ion-color-enable);\n  --border-radius: 20px;\n  direction: ltr;\n}\n.modal-add-new-force :lang(he) {\n  direction: rtl;\n}\n.addNewForceGrid {\n  width: 100%;\n  padding: 24px;\n  padding-top: 0;\n}\n.addNewForceRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding-bottom: 24px;\n}\n.addForceIconRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n.addNewForceButton {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
