import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Observer.css";
import { IonPage } from "@ionic/react";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import TrainingPlanRow from "../TrainingPlanRow";
import ObserverHook from "../../../CustomHooks/ObserverHook";
import ObserverComponent from "./ObserverComponent";
import {
  EGorillaTrainings,
  IGorillaTrainingsForces,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import { mobileRoutes } from "../../../../services/routeRoles";
import { GorillaTrainingContext } from "../../../../context/GorillaTrainingContext/GorillaTrainingContext";
import useNavigation from "../../../CustomHooks/useNavigation";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";
import customToast from "../../Toast/CustomToast";

const Observer: FC = (): JSX.Element => {
  useMobileDataPostingParams();

  const { t } = useTranslation();
  const { replace } = useNavigation();

  const { gorillaTrainingsForces } = useContext(GorillaTrainingContext);
  useEffect(() => {
    const frameTrainingOptions = Object.keys(gorillaTrainingsForces).filter(
      (key) =>
        gorillaTrainingsForces[key as keyof IGorillaTrainingsForces] !==
        undefined
    );
    if (!frameTrainingOptions.length)
      replace(
        `${mobileRoutes.gorillaInitTraining}/${EGorillaTrainings.Observer}`
      );
    // eslint-disable-next-line
  }, [gorillaTrainingsForces]);
  const {
    endTrainingHandlerMOAfterConfirmation,
    openAndCover,
    setWorkRoutine,
    workRoutine,
    settingStation,
    conductingQuality,
    targetAcquisition,
    setTargetAcquisition,
    setOpenAndCover,
    setSettingStation,
    setConductingQuality,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
    traineeName,
    isTrainingEnd,
  } = ObserverHook();
  useEffect(() => {
    if (isTrainingEnd) {
      customToast.success(
        `${t("dataSendSuccess")} ${t("for")} ${t(EGorillaTrainings.Observer)}`
      );
    }
  }, [isTrainingEnd]);
  return (
    <>
      <IonPage id="main-content">
        <div className="containerGorilla containerMO">
          <div className="headerObserver">
            <MobileHeader />
            <TrainingPlanRow
              heatName={t("Observer")}
              withoutMenu
            ></TrainingPlanRow>
          </div>
          {
            <ObserverComponent
              traineeName={traineeName}
              endTrainingHandler={endTrainingHandlerMOAfterConfirmation}
              mainNotes={mainNotes}
              openAndCover={openAndCover}
              settingStation={settingStation}
              conductingQuality={conductingQuality}
              setOpenAndCover={setOpenAndCover}
              setSettingStation={setSettingStation}
              setConductingQuality={setConductingQuality}
              setTargetAcquisition={setTargetAcquisition}
              setWorkRoutine={setWorkRoutine}
              targetAcquisition={targetAcquisition}
              workRoutine={workRoutine}
              deleteNote={deleteNote}
              editNote={editNote}
              addNote={addNote}
              isTrainingEnd={isTrainingEnd}
            />
          }
        </div>
      </IonPage>
    </>
  );
};

export default Observer;
