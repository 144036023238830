// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PMDropdown {
  width: 100%;
}
.PMDropdown.small {
  width: 200px;
}
.selectedItemPMDropdown {
  --background: var(--ion-color-selected);
}
.pmDropdownItem {
  z-index: 1000 !important;
  --background: var(--ion-color-light);
}
.pmDropdownItem:hover,
.pmDropdownItem.selected {
  --background: var(--ion-color-selected);
}

.pmDropdownList {
  padding: 0px;
  z-index: 150;
}
.PMDropdown.disabled {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/PMDropdown/PMDropdown.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,uCAAuC;AACzC;AACA;EACE,wBAAwB;EACxB,oCAAoC;AACtC;AACA;;EAEE,uCAAuC;AACzC;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".PMDropdown {\n  width: 100%;\n}\n.PMDropdown.small {\n  width: 200px;\n}\n.selectedItemPMDropdown {\n  --background: var(--ion-color-selected);\n}\n.pmDropdownItem {\n  z-index: 1000 !important;\n  --background: var(--ion-color-light);\n}\n.pmDropdownItem:hover,\n.pmDropdownItem.selected {\n  --background: var(--ion-color-selected);\n}\n\n.pmDropdownList {\n  padding: 0px;\n  z-index: 150;\n}\n.PMDropdown.disabled {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
