import { FC } from "react";
import "./HasifaTkifaEndPage.css";
import HasifaTkifaTrainingTemplate from "../HasifaTkifaTrainingTemplate";
import { IonCol, IonRadio, IonRow } from "@ionic/react";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../../themeComponents/PMIcon";
import PMLabel from "../../../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
export enum EEndPageState {
  fullCompleted,
  partlyCompleted,
  incomplete,
}
interface IProps {
  onPrevButtonHandler: () => void;
  state: EEndPageState;
}
const HasifaTkifaEndPage: FC<IProps> = (props: IProps): JSX.Element => {
  const { onPrevButtonHandler, state } = props;
  const { t } = useTranslation();

  return (
    <HasifaTkifaTrainingTemplate
      component={
        <IonCol className="finishTrainingColHT">
          <IonRow className="finishTrainingRowHT">
            <PMIcon
              size="xxxLarge"
              iconSrc={
                state === EEndPageState.incomplete
                  ? EIconsSrc.INCOMPLETE
                  : EIconsSrc.COMPLETE
              }
            ></PMIcon>
          </IonRow>
          <IonRow className="finishTrainingRowHT">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="xLarge">
              {t(
                state === EEndPageState.fullCompleted
                  ? "fullCompletedIndication"
                  : state === EEndPageState.partlyCompleted
                  ? "partlyCompletedIndication"
                  : state === EEndPageState.incomplete
                  ? "incompleteIndication"
                  : ""
              )}
            </PMLabel>
          </IonRow>
          <IonRow className="finishTrainingRowHT">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="xLarge">
              {t(
                state === EEndPageState.fullCompleted
                  ? "fullCompletedInstruction"
                  : state === EEndPageState.partlyCompleted
                  ? "partlyCompletedInstruction"
                  : state === EEndPageState.incomplete
                  ? "incompleteInstruction"
                  : ""
              )}
            </PMLabel>
          </IonRow>
        </IonCol>
      }
      startButtonHandler={onPrevButtonHandler}
      startButtonText="prev"
      startButtonIcon
    ></HasifaTkifaTrainingTemplate>
  );
};

export default HasifaTkifaEndPage;
