import React, { useEffect } from "react";

import "./HasifaTkifaTrainingTemplate.css";
import { useTranslation } from "react-i18next";
import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import PMButton from "../../../themeComponents/PMButton";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMLabel from "../../../themeComponents/PMLabel";
import WarningRow from "../../../Shared/SPIKEGorilla/WarningRow";
import ELanguage from "../../../../Enums/ELanguage";
interface IProps {
  headerTitle?: string;
  isToDisplayWarning?: boolean;
  component?: React.ReactNode;
  startButtonHandler?: any;
  startButtonText?: string;
  endButtonHandler?: () => void | undefined;
  endButtonText?: string;
  isDisabledStartButton?: boolean;
  isDisabledEndButton?: boolean;
  startButtonIcon?: boolean;
  endButtonIcon?: boolean;
  isComponentCompleted?: boolean;
}
const HasifaTkifaTrainingTemplate: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const { t, i18n } = useTranslation();
  const {
    endButtonHandler,
    endButtonText,
    headerTitle,
    isToDisplayWarning,
    startButtonHandler,
    startButtonText,
    endButtonIcon,
    isDisabledEndButton,
    isDisabledStartButton,
    startButtonIcon,
    isComponentCompleted,
  } = props;
  const [extraButton, setExtraButton] = React.useState<
    React.ReactNode | undefined
  >();
  const childrenWithProps = React.Children.map(
    props.component,
    (child: any) => {
      return React.cloneElement(child, {
        setExtraButton,
      });
    }
  );

  return (
    <IonGrid
      className={`desktop hasifaTkifaDesktopTemplateWrap ${
        !isPlatform("desktop") ? "touch" : ""
      }`}
    >
      <IonRow className="SGTitleRow">
        <IonCol className="SYCol ">
          <PMLabel fontColor="light" fontFamily="Regular" fontSize="xxLarge">
            {t(headerTitle)}
          </PMLabel>
        </IonCol>
      </IonRow>
      <IonRow
        className={`SGComponentRow scrollM ${
          isComponentCompleted ? "componentCompleted" : ""
        }`}
      >
        {childrenWithProps ? childrenWithProps : null}
      </IonRow>

      <IonRow className="SGDButtonWrap">
        {startButtonHandler ? (
          <PMButton
            color="orange"
            fill="outline"
            label={{
              fontFamily: "Regular",
              fontColor: "orange",
              fontSize: "medium",
            }}
            dir={i18n.language === ELanguage.he ? "ltr" : "rtl"}
            size="medium"
            onClickHandler={() => {
              startButtonHandler && startButtonHandler();
            }}
            icon={
              startButtonIcon
                ? {
                    iconSrc:
                      i18n.language === ELanguage.he
                        ? EIconsSrc.BACK_ICON_HE
                        : EIconsSrc.BACK_ICON_EN,

                    color: "orange",
                    size: "large",
                  }
                : undefined
            }
            isDisabled={isDisabledStartButton}
          >
            {t(startButtonText)}
          </PMButton>
        ) : (
          <div></div>
        )}
        <WarningRow isToDisplay={isToDisplayWarning || false} />
        <IonRow>
          {extraButton && extraButton}
          {endButtonHandler && (
            <PMButton
              color={"orange"}
              label={{
                fontFamily: "Regular",
                fontColor: "dark",
                fontSize: "medium",
              }}
              dir={i18n.language === ELanguage.he ? "rtl" : "ltr"}
              size="medium"
              onClickHandler={() => {
                endButtonHandler && endButtonHandler();
              }}
              icon={
                endButtonIcon
                  ? {
                      iconSrc:
                        i18n.language === ELanguage.he
                          ? EIconsSrc.BACK_ICON_EN
                          : EIconsSrc.BACK_ICON_HE,
                      color: "dark",
                      size: "large",
                    }
                  : undefined
              }
              isDisabled={isDisabledEndButton}
            >
              {t(endButtonText)}
            </PMButton>
          )}
        </IonRow>
      </IonRow>
    </IonGrid>
  );
};

export default HasifaTkifaTrainingTemplate;
