import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import Users from "./Users";
import AddUser, { validateRelatedForce } from "./AddUser";
import PMLoading from "../../../Shared/Alert/PMLoading";
import IUser from "../../../../Interfaces/IUser";
import customToast from "../../../Shared/Toast/CustomToast";
import { UserContext } from "../../../../context/UserContext/userContext";
import "./ManageUsers.css";

const ManageUsers = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userMode, setUserMode] = useState<"add" | "edit">("add");
  const [idToEdit, setIdToEdit] = useState<number>(-1);
  const [updateUsersTable, setUpdateUsersTable] = useState(false);
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);

  // Gets users
  useEffect(() => {
    let mounted: boolean = true;
    const getUsers = async () => {
      const users = await Axios.get(`${baseUrlPMBackend}users/getUsers`);

      if (mounted) {
        setUsers(users.data);
        setIsLoading(false);
      }
    };
    getUsers();

    return () => {
      mounted = false;
    };
  }, [updateUsersTable]);

  // Handles edit clicks
  useEffect(() => {
    if (idToEdit !== -1) {
      setUserMode("edit");
    }
  }, [idToEdit]);

  // Adds user to users list
  const addUserToList = async (user: IUser) => {
    setUsers((prev: IUser[]) => [...prev, user]);
  };

  // Cancels user edit
  const cancelEdit = () => {
    setUserMode("add");
    setIdToEdit(-1);
  };

  // Handles user edit request, sends to server
  const editUserHandler = async (userToEdit: IUser) => {
    if (!validateRelatedForce(userToEdit.role, userToEdit.relatedForce)) {
      customToast.error(t("instructorOrViewerMustHaveRelatedForce"));
      return;
    }
    try {
      // because we don't save the brigade name to the DB we returning the user brigade name from the update request below
      const result = await Axios.post(`${baseUrlPMBackend}users/updateUser`, {
        user: { ...userToEdit, relatedForce: userToEdit.relatedForce?.id },
      });

      if (result.status === 200) {
        //update user if its changed
        user.id === userToEdit.id && setUser(userToEdit);
        customToast.success(t("editUserSuccess"));
        setUserMode("add");
        setIdToEdit(-1);

        //in order to update the brigade name only for the user that was edited
        const usersAfterEdit = users.map((currentUser: IUser) =>
          currentUser.id === userToEdit.id
            ? {
                ...currentUser,
                displayName: userToEdit.displayName,
                role: userToEdit.role,
                emailAddress: userToEdit.emailAddress,
                relatedForce: userToEdit.relatedForce
                  ? {
                      ...userToEdit.relatedForce,
                      brigade_name: result.data.brigade_name,
                    }
                  : undefined,
              }
            : currentUser
        );
        setUsers(usersAfterEdit);
      } else {
        customToast.error(t("editUserFailure"));
      }
    } catch (error) {
      console.log(error);
      customToast.error(t("editUserFailure"));
    }
  };

  return (
    <div className="manageUsersContainer">
      <div className="manageUsers">
        <IonGrid className="usersGrid">
          <IonRow className="manageUsersRow">
            <PMLoading
              isOpen={isLoading}
              message={t("loadingUsers")}
              spinner={"bubbles"}
            />
            <IonCol size="2" className="addUserCol">
              <AddUser
                setUpdateUsersTable={setUpdateUsersTable}
                mode={userMode}
                addUserToList={addUserToList}
                user={users.find((user) => user.id === idToEdit)}
                cancelEditMode={cancelEdit}
                editUserHandler={editUserHandler}
              ></AddUser>
            </IonCol>
            <IonCol className="usersCol">
              <IonRow className="usersRow">
                <Users
                  users={users}
                  setUsers={setUsers}
                  setIdToEdit={setIdToEdit}
                ></Users>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ManageUsers;
