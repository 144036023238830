// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert .alert-wrapper {
  background: var(--ion-color-dark);
  --min-height: 25%;
  --min-width: 20%;
}

.alert h2.alert-title.sc-ion-alert-md {
  font-size: large !important;
  font-family: "Light" !important;
  color: var(--ion-color-fontWhite);
}

.alert h2.alert-sub-title.sc-ion-alert-md {
  padding-top: 1%;
  font-size: medium !important;
  font-family: "Regular" !important;
  color: var(--ion-color-fontWhite);
}

.alert .cancel-button {
  color: var(--ion-color-fontLight);
  background: transparent !important;
  --color-activated: var(--ion-color-orange);
  --color-focused: var(--ion-color-orange);
}

.alert .save-batton {
  background: var(--ion-color-orange);
  color: var(--ion-color-fontLight);
}

.alert .alert-button-group.sc-ion-alert-md {
  bottom: 3%;
  position: absolute;
}

.alert button {
  width: 17%;
  margin-inline-start: 3% !important;
}

.alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;
}
.alert .alert-head {
  direction: ltr;
}

.alert:lang(he) .alert-head {
  direction: rtl;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Alert/Alert.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,+BAA+B;EAC/B,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B,iCAAiC;EACjC,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;EACjC,kCAAkC;EAClC,0CAA0C;EAC1C,wCAAwC;AAC1C;;AAEA;EACE,mCAAmC;EACnC,iCAAiC;AACnC;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".alert .alert-wrapper {\n  background: var(--ion-color-dark);\n  --min-height: 25%;\n  --min-width: 20%;\n}\n\n.alert h2.alert-title.sc-ion-alert-md {\n  font-size: large !important;\n  font-family: \"Light\" !important;\n  color: var(--ion-color-fontWhite);\n}\n\n.alert h2.alert-sub-title.sc-ion-alert-md {\n  padding-top: 1%;\n  font-size: medium !important;\n  font-family: \"Regular\" !important;\n  color: var(--ion-color-fontWhite);\n}\n\n.alert .cancel-button {\n  color: var(--ion-color-fontLight);\n  background: transparent !important;\n  --color-activated: var(--ion-color-orange);\n  --color-focused: var(--ion-color-orange);\n}\n\n.alert .save-batton {\n  background: var(--ion-color-orange);\n  color: var(--ion-color-fontLight);\n}\n\n.alert .alert-button-group.sc-ion-alert-md {\n  bottom: 3%;\n  position: absolute;\n}\n\n.alert button {\n  width: 17%;\n  margin-inline-start: 3% !important;\n}\n\n.alert-button-inner.sc-ion-alert-md {\n  justify-content: center !important;\n}\n.alert .alert-head {\n  direction: ltr;\n}\n\n.alert:lang(he) .alert-head {\n  direction: rtl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
