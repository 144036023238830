import React, { useState, useEffect, useContext, Dispatch } from "react";

// css
import "./Orbat.css";

// components
import ForceDetails from "../../../Shared/Orbat/ForceDetails/ForceDetails";
import OrbatTree from "../../../Shared/Orbat/OrbatTree/OrbatTree";
import axios from "../../../../Axios";

// redux
import { useDispatch, useSelector } from "react-redux";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";
import {
  resetFlags,
  updateForceInTree,
  addForce,
  OrbatTreeActionTypes,
} from "../../../../redux/actions/orbatTreeActions";

// services
import { useWebsocketRegister } from "../../../../services/siteManagementSocket";
import { useTranslation } from "react-i18next";
import {
  flattenObject,
  getForceById,
  getWeaponSight,
  getWeaponType,
  instanceOfIForceType,
  treeFlatting,
} from "../../../../services/helpers";

// ionic imports
import { IonCol, IonGrid, IonRow } from "@ionic/react";

// interfaces
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import {
  baseUrlPMBackend,
  MAX_SELECTED,
} from "../../../../Configurations/consts";
import Alert from "../../../Shared/Alert/Alert";
import customToast from "./../../../Shared/Toast/CustomToast";
import { UserContext } from "../../../../context/UserContext/userContext";
import IForceType from "./../../../../Interfaces/IForceType";
import PMTitle from "../../../themeComponents/PMTitle";
import PMLoading from "../../../Shared/Alert/PMLoading";
import SaveCancelButtons from "../../../Shared/Buttons/SaveCancelButtons";
import { environment } from "../../../../Configurations/consts";
import EEnvironment from "../../../../Enums/EEnvironment";
import IForcePointer from "../../../../Interfaces/IForcePointer";
import IIsDuplicatedForce from "../../../../Interfaces/IIsDuplicatedForce";
import { ForcesContextDesktop } from "../../../../context/ForcesContext/forcesContextProviderDesktop";
import useValidateForce from "../../../CustomHooks/useAddForce";

const Orbat: React.FC = () => {
  const { isAdmin, isInstructor } = useContext(UserContext);
  const { resetCompetencyForces } = useContext(ForcesContextDesktop);
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();
  const [registerMessage] = useWebsocketRegister();
  const [loading, setLoading] = useState<boolean>(false);
  const [isTagIdExist, setIsTagIdExist] = useState<boolean>(false);
  const [checkedForceHasTagId, setCheckedForceHasTagId] =
    useState<boolean>(false);

  const [forceToRemove, setForceToRemove] = useState<IForceTreeNode>();
  const [checkedForce, setCheckedForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );
  const {
    setOpenRestoreAlert,
    openRestoreAlert,
    actionOnSave,
    initIsDuplicated,
    isDuplicated,
    onChangeForceDetail,
    saveValidation,
    setForceWithExistingTag,
  } = useValidateForce(checkedForce, setCheckedForce);

  const [originalCheckedForce, setOriginalCheckedForce] =
    useState<IForceTreeNode>({} as IForceTreeNode);
  const [deletingLoading, setDeletingLoading] = useState<boolean>(false);

  const [alertOKClicked, setAlertOKClicked] = useState<boolean>(false);

  //redux state
  const updateOccured: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).updateOccured;
  const updateResStatus: string = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).updateResStatus;
  const updateErrorMsg: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).updateErrorMsg;

  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;

  const [enableReorder, setEnableReorder] = useState<boolean>(false);
  const [draggedElement, setDraggedElement] = useState<
    HTMLDivElement | undefined
  >();

  const disableEditing = environment?.toString() === EEnvironment.production;

  useEffect(() => {
    if (updateOccured) {
      resetCompetencyForces();
      if (updateResStatus === "OK") {
        setForceWithExistingTag({} as IForceTreeNode);
        customToast.success(t("forceUpdateSuccessMsg"));
      } else if (updateResStatus === "error") {
        if (
          updateErrorMsg &&
          (updateErrorMsg?.includes("notNullMsg") ||
            updateErrorMsg?.includes("wrongLengthMsg"))
        ) {
          if (updateErrorMsg.includes("notNullMsg")) {
            customToast.error(
              `${t("notNullMsg1")} ${t(updateErrorMsg.split("=")[1])} ${t(
                "notNullMsg2"
              )}`
            );
          }
          if (updateErrorMsg.includes("wrongLengthMsg")) {
            customToast.error(
              `${t("wrongLengthMsg1")} ${t(updateErrorMsg.split("=")[1])} ${t(
                "wrongLengthMsg2"
              )}`
            );
          }
        } else if (updateErrorMsg.includes("soldierIdExist")) {
          let [isHeDeleted] = updateErrorMsg.split("?").slice(1);

          if (isHeDeleted.split("=")[1] === "true") {
            setOpenRestoreAlert({
              isOpen: true,
              operation: OrbatTreeActionTypes.UPDATE_FORCE,
            });
          } else {
            customToast.error("traineeExistAndNotDeleted");
          }
        } else {
          customToast.error(t("updateForcesError"));
        }
      }
      setLoading(false);
      dispatch(resetFlags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOccured]);

  useEffect(() => {
    if (registerMessage) {
      if (checkedForce.id) {
        changeTagIdHandler(registerMessage.soldierRFID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerMessage]);

  useEffect(() => {
    if (openRestoreAlert.isOpen && alertOKClicked) {
      actionOnSave();
      setAlertOKClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOKClicked]);

  const changeTagIdHandler = async (tagId: number) => {
    let res = await axios.get(`${baseUrlPMBackend}forces/getForceByByTagId`, {
      params: {
        tagId: tagId,
      },
    });

    if (res.data) {
      setForceWithExistingTag(res.data);
      setIsTagIdExist(true);
    } else {
      if (checkedForce.tag_id) {
        setCheckedForceHasTagId(true);
      } else {
        changeTagBySocket();
      }
    }
  };
  useEffect(() => {
    if (orbatTree) {
      //if orbat changed update the checked force details
      setCheckedForce((prev) => {
        let updatedForce = getForceById(orbatTree, prev.id);
        return updatedForce ? updatedForce : prev;
      });
    }
  }, [orbatTree]);
  const onCancel = () => {
    if (checkedForce.id < 0) {
      setForceToRemove(checkedForce);
    } else {
      setCheckedForce(originalCheckedForce);
    }
  };

  const onForceChecked = (checkedForce: IForceTreeNode[]) => {
    if (checkedForce[0]) {
      setCheckedForce(checkedForce[0]);
      setOriginalCheckedForce(checkedForce[0]);
    } else {
      setCheckedForce({} as IForceTreeNode);
      setOriginalCheckedForce({} as IForceTreeNode);
    }
  };

  const changeTagBySocket = () => {
    onChangeForceDetail("tag_id", registerMessage!.soldierRFID.toString());
  };

  const tagIdExchange = () => {
    changeTagBySocket();
  };

  return (
    <div
      className="orbat-container"
      onDrop={() => {
        draggedElement?.remove();
      }}
    >
      <PMLoading
        isOpen={deletingLoading}
        spinner="bubbles"
        message={t("deletingInProgress")}
      ></PMLoading>
      <div className="orbat">
        <PMLoading isOpen={loading} message={t("loadingData")} />
        <IonGrid className="grid-orbat">
          <IonRow className="main-row">
            <IonCol size="2" className="orbat-tree">
              <OrbatTree
                checked={onForceChecked}
                readonly={false}
                limit={MAX_SELECTED}
                forceToRemove={forceToRemove}
                isAdmin={isAdmin}
                setDeletingLoading={setDeletingLoading}
                disableEditing={disableEditing}
                initIsDuplicated={initIsDuplicated}
                enableReorder={enableReorder}
                setEnableReorder={setEnableReorder}
                draggedElement={draggedElement}
                setDraggedElement={setDraggedElement}
              />
            </IonCol>
            <IonCol className="force-details">
              <IonRow className="container-row">
                <IonRow className="titleRowOrbatDetails">
                  <PMTitle
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="large"
                  >
                    {t("detailsHeader")}
                  </PMTitle>
                </IonRow>
                <IonCol size="10" className="details">
                  <ForceDetails
                    disableEditing={disableEditing || enableReorder} //force details is disabled when editing is disabled or when the re-order functionality is on
                    checkedForce={checkedForce}
                    onChange={onChangeForceDetail}
                    isAdmin={isAdmin}
                    isDuplicated={isDuplicated!}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="2" className="buttons-col" align-self-end>
              {isAdmin || isInstructor ? (
                <SaveCancelButtons
                  disabled={disableEditing || enableReorder}
                  onCancelClickHandler={onCancel}
                  onSaveClickHandler={saveValidation}
                ></SaveCancelButtons>
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <Alert
        header={t("forceHasTagIdAlert")}
        isOpen={checkedForceHasTagId}
        setIsOpen={setCheckedForceHasTagId}
        actionOnSave={changeTagBySocket}
        actionOnCancel={() => setCheckedForceHasTagId(false)}
      />

      <Alert
        header={t("tagIdExixstAlert")}
        isOpen={isTagIdExist}
        setIsOpen={setIsTagIdExist}
        actionOnSave={tagIdExchange}
        actionOnCancel={() => setIsTagIdExist(false)}
      />
      <Alert
        header={t("resoreForceAlertMsg")}
        subHeader={t("resoreForceAlertSubMsg")}
        isOpen={openRestoreAlert.isOpen}
        setIsOpen={(state: boolean) =>
          setOpenRestoreAlert((prev) => ({
            operation: undefined,
            isOpen: false,
          }))
        }
        actionOnSave={() => {
          setAlertOKClicked(true);
        }}
        actionOnCancel={() =>
          setOpenRestoreAlert((prev) => ({
            operation: undefined,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default Orbat;
