import { toast } from "react-toastify";
import { useIonToast } from "@ionic/react";
import "./CustomToast.css";

// Platform detection helper
const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Singleton pattern for Ionic Toast
let ionToast: any;
const initializeIonToast = () => {
  if (!ionToast && isMobile()) {
    const ToastComponent = () => {
      const [present] = useIonToast();
      ionToast = present;
      return null;
    };

    // Inject the Ionic toast component into the app (only once)
    const div = document.createElement("div");
    document.body.appendChild(div);
    import("react-dom").then(({ createRoot }: any) => {
      createRoot(div).render(<ToastComponent />);
    });
  }
};

initializeIonToast();

// Toastify options for desktop
const desktopToast = {
  success(msg: string, options = {}) {
    return toast.success(msg, {
      ...options,
      icon: false,
      className: "toast-success-container toast-success-container-after",
    });
  },
  error(msg: string, options = {}) {
    return toast.error(msg, {
      ...options,
      icon: false,
      className: "toast-error-container toast-error-container-after",
    });
  },
  info(msg: string, options = {}) {
    return toast.info(msg, {
      ...options,
      icon: false,
      className: "toast-info-container toast-info-container-after",
    });
  },
};

// Ionic Toast options for mobile
const mobileToast = (type: "success" | "error" | "info", msg: string) => {
  if (!ionToast) return;

  const colorMap = { success: "success", error: "danger", info: "dark" };

  ionToast({
    message: msg,
    duration: 1500,
    position: "bottom",
    color: colorMap[type],
  });
};

// Unified Toast Utility
const customToast = {
  success(msg: string, options = {}) {
    if (isMobile()) {
      mobileToast("success", msg);
    } else {
      desktopToast.success(msg, options);
    }
  },
  error(msg: string, options = {}) {
    if (isMobile()) {
      mobileToast("error", msg);
    } else {
      desktopToast.error(msg, options);
    }
  },
  info(msg: string, options = {}) {
    if (isMobile()) {
      mobileToast("info", msg);
    } else {
      desktopToast.info(msg, options);
    }
  },
};

export default customToast;
