import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  createRef,
} from "react";
import EditableCell from "./EditableCell";
import "./DataGrid.css";
import { DataGridProps, EEditorType, RowData } from "./DataGridTypes";
import { IonCol, IonGrid, IonHeader, IonItem, IonRow } from "@ionic/react";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { TableVirtuoso, TableVirtuosoHandle } from "react-virtuoso";
import SimpleSelect from "../DataPosting/SimpleSelect";
import { useTranslation } from "react-i18next";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import ELanguage from "../../../Enums/ELanguage";

function DataGrid<T extends RowData>({
  columns,
  data,
  expandable = false,
  selectable = false,
  onSelectionChange,
  editable = false,
  onEdit,
  isWithFilter = false,
  editableRowIds,
  detailsKeysToHide,
  selectedRowIds,
  setLoadMore,
  minWidth,
}: DataGridProps<T>) {
  const virtuosoRef = useRef<TableVirtuosoHandle>(null); // Reference to Virtuoso for scrolling
  const cellRefs = useRef<React.RefObject<HTMLDivElement>[][]>([]); // Refs for editable cells

  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [expandedRowIds, setExpandedRowIds] = useState<Set<number | string>>(
    new Set()
  );

  const handleFilterChange = (columnKey: string, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [columnKey]: value,
    }));
  };

  /** Move to next cell on click tab */
  const handleKeyDown = (
    event: React.KeyboardEvent,
    rowIndex: number,
    colIndex: number
  ) => {
    const tabKey = "Tab"; // Ignore all key presses except for Tab
    if (event.key !== tabKey) {
      return;
    }
    const isShiftPressed = event.shiftKey;
    const numRows = filteredData.length;
    const numCols = columns.length;

    let newRowIndex = rowIndex;
    let newColIndex = colIndex;

    const isColumnEditable = (colIndex: number) => columns[colIndex].editable; // Check if the column is editable

    if (isShiftPressed) {
      // Move backward (Shift + Tab)
      do {
        if (newColIndex === 0) {
          if (newRowIndex > 0) {
            newRowIndex = newRowIndex - 1;
            newColIndex = numCols - 1;
          }
          {
            break;
          }
        } else {
          newColIndex = newColIndex - 1;
        }
      } while (
        !isColumnEditable(newColIndex) &&
        newColIndex >= 0 &&
        newRowIndex >= 0
      );
    } else {
      // Move forward (Tab)
      do {
        if (newColIndex === numCols - 1) {
          if (newRowIndex < numRows - 1) {
            newRowIndex = newRowIndex + 1;
            newColIndex = 0;
          } else {
            break;
          }
        } else {
          newColIndex = newColIndex + 1;
        }
      } while (
        !isColumnEditable(newColIndex) &&
        newColIndex < numCols &&
        newRowIndex < numRows
      );
    }

    // Focus the next editable cell using refs
    const nextEditableCell = cellRefs.current[newRowIndex]?.[newColIndex];
    if (nextEditableCell.current) {
      if (event.key === tabKey) {
        event.preventDefault(); // Prevent default tabbing behavior

        nextEditableCell.current?.focus();
        if (virtuosoRef.current) {
          virtuosoRef.current.scrollToIndex({
            index: newRowIndex,
            align: "center",
          });
        }
      }
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      columns.every((col) => {
        const filterValue = filters[col.key as string];
        if (!filterValue) return true;
        const cellValue = row[col.key];
        if (col.filterType === EEditorType.dropdown) {
          return cellValue === filterValue;
        }
        return cellValue
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      })
    );
  }, [data, columns, filters]);

  const toggleRowExpansion = (rowId: number | string) => {
    const newExpanded = new Set(expandedRowIds);
    if (newExpanded.has(rowId)) {
      newExpanded.delete(rowId);
    } else {
      newExpanded.add(rowId);
    }
    setExpandedRowIds(newExpanded);
  };

  const handleCellSave = (row: T, key: keyof T, newValue: any) => {
    const updatedRow = { ...row, [key]: newValue };
    if (onEdit) {
      onEdit(updatedRow);
    }
  };
  const [duplicateColumn, setDuplicateColumn] = useState<string>("");
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: any | undefined;
    rowId: number | string;
  }>({
    showPopover: false,
    event: undefined,
    rowId: "",
  });

  const duplicateTags = () => {
    //get the row to duplicate
    let rowToDuplicate = data.find((row) => row.id == popoverState.rowId);
    data.map((row) => {
      //for each row in the data duplicate the value for the column
      handleCellSave(
        row,
        duplicateColumn,
        rowToDuplicate ? rowToDuplicate[duplicateColumn] : ""
      );
    });

    setShowPopover({
      showPopover: false,
      event: undefined,
      rowId: "",
    });
  };
  const cellEditorRef = useRef<HTMLDivElement>();
  // Memoized Row Renderer
  const RowRenderer = useCallback(
    (rowIndex: number) => {
      const row = filteredData[rowIndex];
      const isSelected = selectedRowIds?.has(row.id);
      const isExpanded = expandedRowIds.has(row.id);
      if (!cellRefs.current[rowIndex]) {
        cellRefs.current[rowIndex] = columns.map(() =>
          createRef<HTMLDivElement>()
        );
      }
      return (
        <td
          className={`rowRenderWrap ${isSelected ? "selectedRowDG" : ""}`}
          id="rowRenderWrap"
          onClick={(e) => {
            if (onSelectionChange && !editableRowIds?.has(row.id))
              onSelectionChange(row);
          }}
        >
          <React.Fragment key={row.id}>
            <IonRow
              className={` DGRow DGridRow ${
                isExpanded ? "DGExpendedRow" : ""
              }  ${isSelected ? "selected" : ""} ${
                expandable ? "expandableGrid" : ""
              }`}
            >
              {(selectable || expandable) && (
                <IonCol
                  className={`DGCheckboxCol stick-row ${
                    isExpanded ? "stick-row-expended" : ""
                  } ${isSelected ? "selected" : ""}`}
                >
                  <IonRow className="DGRow">
                    {expandable && (
                      <PMIcon
                        iconSrc={
                          isExpanded
                            ? EIconsSrc.ARROW_DOWN
                            : i18n.language === ELanguage.he
                            ? EIconsSrc.CHEVRON_LEFT
                            : EIconsSrc.CHEVRON_RIGHT
                        }
                        size="xLarge"
                        onClick={(event) => {
                          toggleRowExpansion(row.id);
                          event.stopPropagation(); // Prevents the row from being selected
                        }}
                      ></PMIcon>
                    )}
                  </IonRow>
                </IonCol>
              )}

              {columns.map((col, colIndex) => {
                const warning = col.warning ? col.warning(row[col.key]) : null;

                return (
                  <IonCol
                    key={col.key.toString()}
                    style={{
                      minWidth: col.minWidth
                        ? `${col.minWidth}px`
                        : col.width
                        ? `${col.width}px`
                        : `${minWidth}px`,
                      width: col.width ? `${col.width}px` : `${minWidth}px`,
                      maxWidth: col.width ? `${col.width}px` : undefined,
                      paddingInlineStart:
                        editable || editableRowIds?.has(row.id) ? "3px" : "8px",
                    }}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      cellEditorRef.current?.blur();
                      setDuplicateColumn(String(col.key));
                      if (editable)
                        setShowPopover({
                          showPopover: true,
                          event: event,
                          rowId: row.id,
                        });
                    }}
                    onKeyDown={(event: any) =>
                      handleKeyDown(event, rowIndex, colIndex)
                    }
                  >
                    {(editable || editableRowIds?.has(row.id)) &&
                    col.editable ? (
                      col.renderEditCell ? (
                        <col.renderEditCell
                          row={row}
                          onRowChange={(value: any) => {
                            onEdit && onEdit(value);
                          }}
                          column={col}
                          value={row[col.key]}
                        />
                      ) : (
                        <EditableCell
                          ref={cellRefs.current[rowIndex][colIndex]} // Attach ref here
                          value={row[col.key]}
                          editable={
                            editable || editableRowIds?.has(row.id) || false
                          }
                          options={col.options}
                          type={
                            col.filterType === EEditorType.dropdown
                              ? EEditorType.dropdown
                              : EEditorType.text
                          }
                          onSave={(newValue: any) =>
                            handleCellSave(row, col.key, newValue)
                          }
                          warning={warning}
                          validationFunction={col.validationFunction}
                          row={row}
                          key={col.key.toString()}
                          id={`cell-${rowIndex}-${colIndex}`} // Add class to uniquely identify each cell
                        />
                      )
                    ) : (
                      <>
                        <PMLabel> {row[col.key]}</PMLabel>
                        {warning && (
                          <PMLabel fontSize="small" fontColor="notAttended">
                            {warning}
                          </PMLabel>
                        )}
                      </>
                    )}
                  </IonCol>
                );
              })}
            </IonRow>
            {expandable ? (
              <IonRow
                className={`expanded-row ${expandable ? "DGExpendedRow" : ""} ${
                  isExpanded ? "show" : ""
                } ${isSelected ? "selected" : ""}`}
              >
                {Object.entries(row)
                  .filter(([key, value]) => {
                    return (
                      !detailsKeysToHide || !detailsKeysToHide.includes(key)
                    );
                  })
                  .sort(([keyA, _valueA], [keyB, _valueB]) =>
                    keyB.localeCompare(keyA)
                  )
                  .map(([key, value], index) => {
                    return (
                      <IonCol key={index} className="DGExpendedCol">
                        <PMLabel
                          fontFamily="Light"
                          fontSize="small"
                          fontColor="disabled"
                        >
                          {t(key)}
                        </PMLabel>
                        <PMLabel
                          fontSize="medium"
                          fontFamily="Light"
                          fontColor="light"
                        >
                          {t(value)}
                        </PMLabel>
                      </IonCol>
                    );
                  })}
              </IonRow>
            ) : null}
          </React.Fragment>
        </td>
      );
    },
    [
      filteredData,
      selectedRowIds,
      expandedRowIds,
      selectable,
      expandable,
      columns,
      editable,
      editableRowIds,
      detailsKeysToHide,
      t,
      handleCellSave,
      toggleRowExpansion,
    ]
  );

  const Header = () => (
    <tr>
      <th>
        <IonHeader className="dataGridHeader">
          <IonCol className="paddingFree">
            <IonRow className="DGRow">
              {(selectable || expandable) && (
                <IonCol className="DGCheckboxCol"></IonCol>
              )}
              {columns.map((col) => (
                <IonCol
                  className="headerCol"
                  key={col.key.toString()}
                  style={{
                    minWidth: col.minWidth
                      ? `${col.minWidth}px`
                      : col.width
                      ? `${col.width}px`
                      : `${minWidth}px`,
                    width: col.width ? `${col.width}px` : `${minWidth}px`,
                    maxWidth: col.width ? `${col.width}px` : undefined,
                  }}
                >
                  <PMLabel
                    fontFamily="SemiBold"
                    fontSize="medium"
                    cssClass="dataGridHeaderLabel"
                  >
                    {col.header}
                  </PMLabel>
                  {isWithFilter && col.filterType !== undefined && (
                    <div className="filter-cell">
                      {col.filterType === EEditorType.dropdown &&
                      col.options ? (
                        <SimpleSelect
                          options={[
                            { label: t("clear"), value: "" },
                            ...col.options,
                          ]}
                          value={{
                            label: filters[col.key as string] || "",
                            value: filters[col.key as string],
                          }}
                          onSelect={(selectedOption) =>
                            handleFilterChange(
                              col.key as string,
                              selectedOption?.value
                            )
                          }
                        />
                      ) : (
                        <IonRow className="filterRow">
                          <input
                            type="text"
                            value={filters[col.key as string] || ""}
                            onChange={(e) =>
                              handleFilterChange(
                                col.key as string,
                                e.target.value
                              )
                            }
                            className="filterInput"
                          />
                          <PMIcon
                            size="large"
                            iconSrc={
                              filters[col.key as string]?.length
                                ? EIconsSrc.CLOSE
                                : EIconsSrc.SEARCH
                            }
                            onClick={() =>
                              handleFilterChange(col.key as string, "")
                            }
                          />
                        </IonRow>
                      )}
                    </div>
                  )}
                </IonCol>
              ))}
            </IonRow>
          </IonCol>
        </IonHeader>
      </th>
    </tr>
  );

  return (
    <IonGrid className={"data-grid"}>
      <IonCol className="DGCol">
        <IonRow className="DGDataRow">
          <IonCol className="paddingFree">
            <PopoverMenu
              popoverState={popoverState}
              onDismiss={() =>
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                  rowId: "",
                })
              }
            >
              <IonItem
                button
                onClick={duplicateTags}
                className={"duplicateItem"}
              >
                <p className="menuText menuTextGeneral">
                  {t("duplicateToAllRows")}
                </p>
              </IonItem>
            </PopoverMenu>
            <TableVirtuoso
              ref={virtuosoRef} // Attach reference to the Virtuoso component
              className="scrollL"
              data={filteredData}
              itemContent={(index: number) => RowRenderer(index)}
              allowFullScreen
              totalCount={filteredData.length}
              height={350}
              fixedHeaderContent={Header}
              atBottomStateChange={setLoadMore}
              atBottomThreshold={1}
            />
          </IonCol>
        </IonRow>
      </IonCol>
    </IonGrid>
  );
}

export default DataGrid;
