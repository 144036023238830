import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import "./BilliardDesktop.css";
import IDataPosting from "../../../Interfaces/IDataPosting";
import { EDataPostingTrainingTypes } from "../../CustomHooks/useDataPostingTabs";
import BilliardComponent from "../../../pages/Mobile/Billiard/BilliardComponent";
const BilliardDesktop: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);
  const trainingTyeBilliardId = 35;
  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);
  const [isSendEnabled, setIsSendDisable] = useState<boolean>(false);
  useEffect(() => {
    if (isSendDataClicked) {
      setSendDataClicked(false);
      setSoldierDetails([]);
    }
  }, [isSendDataClicked]);

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={EDataPostingTrainingTypes.billiard}
      onClickThirdButton={() => {
        setSendDataClicked(true);
      }}
      isSendDisabled={isSendEnabled}
    >
      <IonGrid className="searchGrid">
        <IonRow className="searchRow">
          <IonCol size="2" className="searchCol">
            <SearchComponents
              soldierDetails={soldierDetails}
              setSoldierDetails={setSoldierDetails}
              isDesktop={true}
              selectedForcesAmount={2}
              isPairs
              isBilliard
            />
          </IonCol>
          <IonCol size="1" className="searchCol"></IonCol>
          <IonCol size="9" className="col2Medical">
            <BilliardComponent
              trainees={soldierDetails as any}
              trainingTypeId={Number(trainingTyeBilliardId)}
              planId={Number(props.selectedPlan?.id)}
              isSendClicked={isSendDataClicked}
              isDesktop
              setIsSendDisable={setIsSendDisable}
            ></BilliardComponent>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default BilliardDesktop;
