// css
import { IonCol, IonList, IonRow } from "@ionic/react";
import "./IndicatorFinalGrade.css";
import PMLabel from "../../../components/themeComponents/PMLabel";
import FinalGradeDisplay from "../../../components/Shared/FinalGradeDisplay/FinalGradeDisplay";
import { useTranslation } from "react-i18next";

interface IProps {
  grade: number | undefined | null;
  threshold: number | undefined | null;
  hitRate?: string;
  notes: string[] | undefined;
}
const IndicatorFinalGrade: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <IonCol size="3.5" className="IndicatorFinalGradeWrap">
      <div className="singleGunnerIndicatorWrap trainingTypeGeneral scrollL">
        {props.notes ? (
          <>
            <IonRow className="finalGradeWrap">
              <IonRow className="trainingTypeTitleWrap">
                <PMLabel
                  fontColor="light"
                  fontFamily="Bold"
                  cssClass="gorillaTitleFont"
                >
                  {t("finalTrainingGrade")}
                </PMLabel>
                <PMLabel
                  fontColor="light"
                  fontFamily="Bold"
                  cssClass="gorillaTitleFont"
                >
                  {props.hitRate
                    ? `${t("overall")} ${props.hitRate} ${t("hits")}`
                    : ""}
                </PMLabel>
              </IonRow>
              <IonRow className="trainingTypeFinalGradWrap">
                <div className="finalGradDiv">
                  <FinalGradeDisplay
                    threshold={props.threshold || 0}
                    soldierFinalGrade={{
                      grade: props.grade || 0,
                      legendColor: "transparent",
                      id: 0,
                      isAttended: !!props.grade && props.grade >= 0,
                      name: "",
                    }}
                  ></FinalGradeDisplay>
                </div>
              </IonRow>
            </IonRow>
            {props.notes.length ? (
              <IonCol className="generalCommentsRow scrollS">
                <IonRow>
                  <PMLabel
                    fontColor="light"
                    fontSize="large"
                    fontFamily="Regular"
                  >
                    {t("mainNotes")}
                  </PMLabel>
                </IonRow>
                <IonRow>
                  <IonList className="generalCommentsList">
                    {props.notes &&
                      props.notes.map((note, index) => (
                        <div className="commentResRow" key={index}>
                          <span className="commentDot"></span>
                          <PMLabel
                            fontColor="light"
                            fontSize="medium"
                            fontFamily="Light"
                          >
                            {note}
                          </PMLabel>
                        </div>
                      ))}
                  </IonList>
                </IonRow>
              </IonCol>
            ) : null}
          </>
        ) : null}
      </div>
    </IonCol>
  );
};
export default IndicatorFinalGrade;
