import { useEffect, useState } from "react";
import { useGetTrainees } from "../../../CustomHooks/HooksWithRQ/useGetTrainees";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";

const TRAINEES_RENDER_CHUNK_SIZE = 100;
const useGetFilterForce = (billiardForcesIds?: number[]) => {
  const { soldiersNamesAndIds } = useGetTrainees();

  const [traineesArray, setTraineesArray] = useState<ISimpleTrainee[]>([]);

  const [hasMoreTrainees, setHasMoreTrainees] = useState<boolean>(true);
  const [listCounter, setListCounter] = useState({
    prev: 0,
    next: TRAINEES_RENDER_CHUNK_SIZE,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [displayedArray, setDisplayedArray] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string>("");

  /** when we've reached the end of the list increase the counter*/
  const loadMoreData = () => {
    // If we've reached the end of the list ,set hasMore to be false
    if (displayedArray.length === traineesArray.length) {
      setHasMoreTrainees(false);
      return;
    }

    setListCounter((prevState) => ({
      prev: prevState.prev + TRAINEES_RENDER_CHUNK_SIZE,
      next: prevState.next + TRAINEES_RENDER_CHUNK_SIZE,
    }));
  };
  const getTrainees = (soldiersNamesAndIds: ISimpleTrainee[]) =>
    soldiersNamesAndIds.filter(
      (f) => !billiardForcesIds || billiardForcesIds?.includes(Number(f.uniqId))
    );
  /** load all trainees from DB if not exist in context */
  useEffect(() => {
    if (soldiersNamesAndIds?.length) {
      setTraineesArray(getTrainees(soldiersNamesAndIds));
    } else {
      setLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldiersNamesAndIds, billiardForcesIds]);

  useEffect(() => {
    //when search text changed filter the trainees array to be only with the searched text
    setTraineesArray(
      getTrainees(soldiersNamesAndIds).filter(
        (soldier) =>
          String(soldier.id).includes(searchText) ||
          soldier.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, billiardForcesIds]);

  useEffect(() => {
    setDisplayedArray(traineesArray.slice(0, listCounter.next));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traineesArray]);

  //when the counter in changed add more trainees to the displayedArray
  useEffect(() => {
    setDisplayedArray(
      displayedArray.concat(
        traineesArray.slice(listCounter.prev, listCounter.next)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCounter]);

  useEffect(() => {
    //turn off the loading if there is trainees to display
    if (displayedArray.length) {
      setLoading(false);
    }
  }, [displayedArray]);
  return {
    soldiersNamesAndIds,
    loading,
    setSearchText,
    searchText,
    displayedArray,
    loadMoreData,
    hasMoreTrainees,
  };
};
export default useGetFilterForce;
