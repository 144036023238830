import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { environment } from "../../../Configurations/consts";
import i18n from "../../../services/i18next";
import { routeRoles, userRoles } from "../../../services/routeRoles";
import { About } from "../../Shared/About/About";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import SecurityContact from "../../Shared/SecurityContact/SecurityContact";
import PMLabel from "../../themeComponents/PMLabel";
import PopoverItem from "../../themeComponents/PopoverItem";
import { UserContext } from "../../../context/UserContext/userContext";
import IPopoverState from "../../../Interfaces/IPopoverState";
import customToast from "../../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";
import LastSiteSync from "../../Shared/LastSiteSync/LastSiteSync";
import ILastSync from "../../../Interfaces/ILastSync";
import TrackingUsersLogins from "../../Shared/TrackingUsersLogins/TrackingUsersLogins";
import EEnvironment from "../../../Enums/EEnvironment";
import { syncData } from "../../../apis/SyncAPI";
import Spinner from "../../Shared/Spinner/Spinner";
import "./HeaderMenu.css";
import { SyncContext } from "../../../context/SyncContext/SyncContext";
import MicrosoftLogout from "../../CustomHooks/MicrosoftLogout";
import ExportImportTranslationsFile from "../../Shared/ImportTranslationsFile/ImportTranslationsFile";
import { AppModePopover } from "../../Shared/AppModeChange/AppModeChange";
import { LanguageChangePopover } from "../../Shared/LanguageChangePopover/LanguageChangePopover";

import Axios from "../../../Axios";
import { EAppMode } from "../../../Enums/EAppMode";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
import ELanguage from "../../../Enums/ELanguage";
import { isValidAppMode } from "../../../services/helpers";
interface IHeaderMenuProps {
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  darkMode: boolean;
  popoverState: IPopoverState;
  onDismiss: () => void;
  isMobile?: boolean;
  onComparePlansClicked?: () => Promise<void>;
  onCompareGradesClicked?: () => Promise<void>;
  isComperingPlans?: boolean;
  isComperingGrades?: boolean;
}

const HeaderMenu = (props: IHeaderMenuProps) => {
  const isToDisplay = false;
  const { logoutUser } = MicrosoftLogout();
  const { setSyncEnded, setSyncStarted } = useContext(SyncContext);
  const { user, logout } = useContext(UserContext);
  const { t } = useTranslation();
  const [lastSynced, setLastSynced] = useState<ILastSync[]>([]);
  const [trackingUsersLoginsClicked, setTrackingUsersLoginsClicked] =
    useState<boolean>(false);
  const { appMode } = useContext(AppModeContext);
  useEffect(() => {
    let mounted: boolean = true; // in order to ensure that the data wont be presented if "mounted" is false

    mounted && getLastSynced();
    return () => {
      mounted = false;
    };
  }, []);

  const getLastSynced = async () => {
    // await getLastSync().then((response: AxiosResponse<ILastSync[]>) =>
    //   setLastSynced(response.data)
    // );
  };

  const localSiteLogout = async () => {
    logout();
  };

  const synchronizeData = async () => {
    props.onDismiss();
    setSyncStarted(true);

    await syncData()
      .then((result: { data: { is_equal_grades: boolean } }) => {
        setSyncEnded(true);
        getLastSynced();
        if (result?.data?.is_equal_grades)
          customToast.success(t("syncDataSuccessMgs"));
        else customToast.error(t("syncDataGradesNotEqualMgs"));
      })
      .catch((error: any) => {
        console.error(error);
        if (error?.response?.status === 429)
          customToast.error(t("syncServiceAlreadyInProgress"));
        else {
          setSyncEnded(true);
          customToast.error(t("syncDataErrorMgs"));
        }
      });
  };

  return (
    <PopoverMenu popoverState={props.popoverState} onDismiss={props.onDismiss}>
      {
        /* <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
      >
        <ToggleThemeColor
          darkMode={props.darkMode}
          setDarkMode={props.setDarkMode}
        />
      </PopoverItem> */
        //Don't remove!! for now theme toggle is disabled
      }
      {environment && environment.toString() !== "localSite" ? (
        <PopoverItem
          fontColor="light"
          onClickHandler={logoutUser}
          background="Blight"
        >
          <PMLabel textAlign="center" fontFamily="Light" fontSize="medium">
            {t("logout")}
          </PMLabel>
        </PopoverItem>
      ) : (
        <PopoverItem
          fontColor="light"
          onClickHandler={localSiteLogout}
          background="Blight"
        >
          <PMLabel textAlign="center" fontFamily="Light" fontSize="medium">
            {t("logout")}
          </PMLabel>
        </PopoverItem>
      )}
      {environment !== EEnvironment.staging && isToDisplay ? (
        <PopoverItem
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
          background="Blight"
        >
          <SecurityContact />
        </PopoverItem>
      ) : null}
      <LanguageChangePopover />

      <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
      >
        <About />
      </PopoverItem>
      {!props.isMobile && (
        <>
          {environment === EEnvironment.production ? (
            <PopoverItem
              fontColor="light"
              fontFamily="Light"
              fontSize="medium"
              background="Blight"
              onClickHandler={() => {
                setTrackingUsersLoginsClicked(true);
              }}
            >
              <TrackingUsersLogins
                trackingUsersLoginsClicked={trackingUsersLoginsClicked}
                setTrackingUsersLoginsClicked={setTrackingUsersLoginsClicked}
              />
            </PopoverItem>
          ) : null}
          {environment === EEnvironment.localSite &&
          isValidAppMode(appMode, EAppMode.WARTAC) &&
          isToDisplay ? (
            <>
              <PopoverItem
                background="Blight"
                onClickHandler={props.onComparePlansClicked}
              >
                <div className="compareGradesDiv">
                  <PMLabel
                    textAlign="center"
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {t("comparePlans")}
                  </PMLabel>
                  {props.isComperingPlans ? (
                    <Spinner className="compareSpinner" />
                  ) : null}
                </div>
              </PopoverItem>
              <PopoverItem
                background="Blight"
                onClickHandler={props.onCompareGradesClicked}
              >
                <div className="compareGradesDiv">
                  <PMLabel
                    textAlign="center"
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {t("compareGrades")}
                  </PMLabel>
                  {props.isComperingGrades ? (
                    <Spinner className="compareSpinner" />
                  ) : null}
                </div>
              </PopoverItem>
            </>
          ) : null}

          {routeRoles.Admins.includes(user.role) &&
          environment === EEnvironment.localSite &&
          isValidAppMode(appMode, EAppMode.WARTAC) &&
          isToDisplay ? (
            <>
              <PopoverItem
                fontColor="light"
                fontFamily="Light"
                fontSize="medium"
                background="Blight"
                onClickHandler={() => getLastSynced()}
              >
                <LastSiteSync lastSynced={lastSynced} />
              </PopoverItem>
              <PopoverItem
                background="BxLight"
                onClickHandler={() => synchronizeData()}
              >
                <PMLabel
                  textAlign="center"
                  fontColor="light"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {`${t("syncData")}`}
                </PMLabel>
              </PopoverItem>
            </>
          ) : null}
        </>
      )}
      {user.role === userRoles.Admin && <ExportImportTranslationsFile />}
      {environment === EEnvironment.staging &&
        (user.role === userRoles.Admin || user.isBagiraUser) && (
          <PopoverItem
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            background="Blight"
          >
            <AppModePopover />
          </PopoverItem>
        )}
    </PopoverMenu>
  );
};

export default HeaderMenu;
