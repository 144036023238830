// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-form {
  padding-bottom: 5%;
  padding-top: 1%;
  width: max-content;
}

.detail-row {
  margin-top: 5%;
}

.dropdown-selector {
  width: 8vw;
  z-index: 9;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: var(--ion-color-orange);
  margin-inline-start: 5px;
  margin-inline-end: 5px;
}

.colForceDetails {
  display: flex;
  flex-direction: row;
}

.checkoutButton {
  margin: 10px;
  margin-top: 30px;
}

.detailsRowsContainer {
  width: 90%;
  margin-top: 30px;
}
.buttonsWrap {
  display: flex;
  flex-direction: row;
}

.weaponIdLTROnly {
  direction: ltr !important;
}
.weaponIdLTROnly:lang(he) {
  text-align: end;
}
.weaponIdLTROnly .labelRow:lang(he) {
  display: flex;
  justify-content: flex-end !important;
  align-self: flex-end;
}
.forceDetailsButton {
  margin-inline-end: 12px;
}
.xmlSpinner {
  height: 20px;
  margin: 5px;
  color: var(--ion-color-orange);
}
.detailsRowsContainer .titleLabelFD {
  margin-inline-start: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Orbat/ForceDetails/ForceDetails.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,qCAAqC;EACrC,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,oCAAoC;EACpC,oBAAoB;AACtB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".details-form {\n  padding-bottom: 5%;\n  padding-top: 1%;\n  width: max-content;\n}\n\n.detail-row {\n  margin-top: 5%;\n}\n\n.dropdown-selector {\n  width: 8vw;\n  z-index: 9;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 8px;\n  border-color: var(--ion-color-orange);\n  margin-inline-start: 5px;\n  margin-inline-end: 5px;\n}\n\n.colForceDetails {\n  display: flex;\n  flex-direction: row;\n}\n\n.checkoutButton {\n  margin: 10px;\n  margin-top: 30px;\n}\n\n.detailsRowsContainer {\n  width: 90%;\n  margin-top: 30px;\n}\n.buttonsWrap {\n  display: flex;\n  flex-direction: row;\n}\n\n.weaponIdLTROnly {\n  direction: ltr !important;\n}\n.weaponIdLTROnly:lang(he) {\n  text-align: end;\n}\n.weaponIdLTROnly .labelRow:lang(he) {\n  display: flex;\n  justify-content: flex-end !important;\n  align-self: flex-end;\n}\n.forceDetailsButton {\n  margin-inline-end: 12px;\n}\n.xmlSpinner {\n  height: 20px;\n  margin: 5px;\n  color: var(--ion-color-orange);\n}\n.detailsRowsContainer .titleLabelFD {\n  margin-inline-start: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
