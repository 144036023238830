import React, { useState, useEffect, useRef, forwardRef } from "react";
import Select from "react-select";
import "./EditableCell.css";
import PMLabel from "../../themeComponents/PMLabel";
import SimpleSelect from "../DataPosting/SimpleSelect";
import { useTranslation } from "react-i18next";
import { EEditorType } from "./DataGridTypes";

interface EditableCellProps {
  value: any;
  editable: boolean; // Column-specific editability
  options?: { label: string; value: string }[];
  onSave: (newValue: any) => void;
  type?: EEditorType;
  warning?: string | null;
  validationFunction?: ((row: any, value: any) => string) | undefined;
  row: any;
  className?: string;
  id?: string;
}

const EditableCell: any = forwardRef<HTMLInputElement, EditableCellProps>(
  (props, ref) => {
    const { editable, onSave, row, value, options, type, validationFunction } =
      props;

    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState<any>(value);
    const wrapDivRef = useRef<HTMLDivElement>(null); // Create a ref for the input

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleSave = () => {
      validateValue();
      setIsEditing(false);
      if (currentValue !== value) {
        onSave(currentValue);
      }
    };

    const handleClick = () => {
      wrapDivRef.current?.focus();

      if (editable) {
        setIsEditing(true);
      }
    };

    const [err, setErr] = useState<string>();
    const validateValue = () => {
      if (validationFunction) {
        let err = validationFunction(row, currentValue);
        if (err.length) setErr(err);
        else setErr(undefined);
      }
    };
    useEffect(() => {
      handleSave();
    }, [currentValue]);

    return (
      <div
        ref={wrapDivRef}
        className={`editable-cell ${props.className}`}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Escape") {
            if (isEditing) handleSave();
          }
        }}
        onFocus={() => {
          console.log(isEditing);

          handleSave();
        }}
      >
        {type === EEditorType.dropdown && options && options?.length > 0 ? (
          <SimpleSelect
            options={options!}
            value={
              options!.find((option) => option.value === currentValue) ?? null
            }
            onSelect={(selectedOption: any) => {
              setCurrentValue(selectedOption?.value);
            }}
            onBlur={handleSave}
          />
        ) : (
          <div className="WarningCell">
            <input
              ref={ref}
              type="text"
              value={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
              onBlur={handleSave}
              onKeyDown={(e) => e.key === "Enter" && handleSave()}
              className="ECInput"
              id={`${props.id}`}
              autoComplete="off"
            />
            {err && currentValue && (
              <PMLabel
                fontSize="xSmall"
                fontFamily="Regular"
                fontColor="notAttended"
                cssClass="cellInputValue"
              >
                {t(err)}
              </PMLabel>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default EditableCell;
