import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import "./AddNewForceModal.css";
import { useTranslation } from "react-i18next";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMButton from "../../../themeComponents/PMButton";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import useValidateForce from "../../../CustomHooks/useAddForce";
import PMLabel from "../../../themeComponents/PMLabel";
import PMIcon from "../../../themeComponents/PMIcon";
import { getWeaponSight, getWeaponType } from "../../../../services/helpers";
import { useWeapons } from "../../../CustomHooks/HooksWithRQ/useWeapons";
import { billiardForceId } from "../../../../Configurations/consts";
import Alert from "../../Alert/Alert";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const AddNewForceModal: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = props;
  const { weapons } = useWeapons();
  const [alertOKClicked, setAlertOKClicked] = useState<boolean>(false);
  const [checkedForce, setCheckedForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );

  const setNewForce = () => {
    setCheckedForce({
      id: Math.floor(Math.random() * -1000),
      is_deleted: false,
      level: 2,
      name: "",
      nodes: null,
      parent_id: Number(billiardForceId),
      soldier_id: null,
      tag_id: null,
      weapon_id: "0",
      weapon_type: getWeaponType(weapons), //default value
      weapon_sight: getWeaponSight(weapons), //default value
      weapon_sight_id: 0,
      force_type: "מתאמן",
      is_soldier: true,
      personal_id: null,
      magazine_id: null,
      laser_id: null,
      head_sensor_id: null,
    });
  };

  useEffect(() => {
    if (!Object.keys(checkedForce).length && weapons.length) {
      setNewForce();
      setIsOpen(false);
    }
  }, [checkedForce, weapons]);

  const {
    isDuplicated,
    onChangeForceDetail,
    saveValidation,
    actionOnSave,
    openRestoreAlert,
    setOpenRestoreAlert,
  } = useValidateForce(checkedForce, setCheckedForce);

  useEffect(() => {
    if (openRestoreAlert.isOpen && alertOKClicked) {
      actionOnSave();
      setAlertOKClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOKClicked]);

  return (
    <IonModal
      className="modal-add-new-force"
      isOpen={isOpen}
      initialBreakpoint={0.6}
      breakpoints={[0, 0.4, 0.6, 0.9, 1]}
      backdropBreakpoint={1} // don't show the backdrop at all. set to 0 and reload if you want to see the changes
      onDidDismiss={() => {
        setIsOpen(false);
      }}
    >
      <IonGrid className="addNewForceGrid">
        <IonCol>
          <IonRow className="addForceIconRow">
            <PMIcon
              iconSrc={EIconsSrc.CLOSE}
              size="large"
              onClick={() => {
                setIsOpen(false);
              }}
            ></PMIcon>
          </IonRow>
          <IonRow className="addNewForceRow ">
            <PMLabel fontColor="light" fontFamily="SemiBold" fontSize="xxLarge">
              {t("addBtnTooltip")}
            </PMLabel>
          </IonRow>
          <IonRow className="addNewForceRow">
            <PMInput
              inputValue={checkedForce.name}
              inputName="name"
              placeholder={t("name")}
              inputType="text"
              onChangeHandler={onChangeForceDetail}
              height="settingsHeight"
            />
          </IonRow>
          <IonRow className="addNewForceRow">
            <PMInput
              errorCss={
                (checkedForce.is_soldier &&
                  isDuplicated?.isDuplicatedSoldier.soldier_id) ||
                isDuplicated?.isDuplicatedSoldier.tag_id
                  ? "tpInputError"
                  : undefined
              }
              inputValue={checkedForce.soldier_id && checkedForce.soldier_id}
              inputName="soldier_id"
              placeholder={t("employeeId")}
              inputType="text"
              onChangeHandler={(filed, value) => {
                onChangeForceDetail(filed, value);
                onChangeForceDetail("tag_id", value);
              }}
              height="settingsHeight"
            />
          </IonRow>
          <IonRow className="addNewForceRow">
            <PMInput
              inputValue={checkedForce.personal_id && checkedForce.personal_id}
              inputName="personal_id"
              placeholder={t("emailAddress")}
              inputType="text"
              onChangeHandler={(filed, value) => {
                onChangeForceDetail(filed, value);
              }}
              height="settingsHeight"
              errorCss={
                isDuplicated?.isDuplicatedSoldier.personal_id ||
                isDuplicated?.isDuplicatedGroup.personal_id
                  ? "tpInputError"
                  : undefined
              }
            />
          </IonRow>
          <IonRow className="addNewForceRow">
            <PMButton
              cssClass="addNewForceButton"
              color="orange"
              label={{ fontColor: "light", fontSize: "medium" }}
              onClickHandler={saveValidation}
            >
              {t("save")}
            </PMButton>
          </IonRow>
          <Alert
            header={t("resoreForceAlertMsg")}
            subHeader={t("resoreForceAlertSubMsg")}
            isOpen={openRestoreAlert.isOpen}
            setIsOpen={(state: boolean) =>
              setOpenRestoreAlert((prev) => ({
                operation: undefined,
                isOpen: false,
              }))
            }
            actionOnSave={() => {
              setAlertOKClicked(true);
            }}
            actionOnCancel={() =>
              setOpenRestoreAlert((prev) => ({
                operation: undefined,
                isOpen: false,
              }))
            }
          />
        </IonCol>
      </IonGrid>
    </IonModal>
  );
};

export default AddNewForceModal;
