import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ITrainingPlanHashtagsResults } from "../../Interfaces/IHashtag";

const useGetHashtagsResults = (
  forcesIds: number[] | undefined,
  planId: number | undefined
) => {
  const hashtagsQueryKey = "hashtagsResults";
  const queryClient = useQueryClient();

  const getHashtagsResults = async (forcesIds: number[], planId?: number) => {
    const response = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getHashtagsResults`,
      {
        params: {
          planId,
          forcesIds,
        },
      }
    );
    return response.data;
  };

  const isEnabled =
    forcesIds !== undefined && forcesIds.length > 0 && planId !== undefined;

  const fetchData = async (): Promise<ITrainingPlanHashtagsResults[]> => {
    // Identify uncached forcesIds
    const uncachedForcesIds = forcesIds!.filter((forceId) => {
      const cacheKey = [hashtagsQueryKey, planId, forceId];
      return !queryClient.getQueryData(cacheKey);
    });

    // Fetch uncached data if necessary
    if (uncachedForcesIds.length > 0) {
      const results = await getHashtagsResults(uncachedForcesIds, planId);

      // Cache results per forceId
      results.forEach((result: ITrainingPlanHashtagsResults) => {
        const cacheKey = [hashtagsQueryKey, planId, result.forceId];
        queryClient.setQueryData(cacheKey, result);
      });
    }

    // Aggregate all cached data
    const cachedData = forcesIds!
      .map((forceId) =>
        queryClient.getQueryData<ITrainingPlanHashtagsResults>([
          hashtagsQueryKey,
          planId,
          forceId,
        ])
      )
      .filter(
        (data): data is ITrainingPlanHashtagsResults => data !== undefined
      ); // Remove undefined values

    return cachedData;
  };

  const { data, isLoading, refetch } = useQuery<ITrainingPlanHashtagsResults[]>(
    {
      queryKey: [hashtagsQueryKey, planId, forcesIds],
      queryFn: fetchData,
      enabled: isEnabled,
      staleTime: Infinity,
    }
  );

  const refresh = async () => {
    queryClient.clear(); // Clear cache
    refetch(); // Trigger the refetch
  };

  return {
    hashtagsData: data,
    isLoading,
    refresh,
  };
};

export default useGetHashtagsResults;
