import React, { useState, useEffect, useContext, Dispatch } from "react";
import "./ForceDetails.css";
import axios from "../../../../Axios";
import {
  baseUrlPMBackend,
  environment,
} from "../../../../Configurations/consts";
import { useTranslation } from "react-i18next";
import {
  getForceFullName,
  instanceOfIForceType,
} from "../../../../services/helpers";
import { IonRow, IonCol, IonSpinner } from "@ionic/react";
import IForceTreeNode from "./../../../../Interfaces/IForceTreeNode";
import EEventType from "../../../../Interfaces/EEventType";
import IPMCheckOut from "../../../../Interfaces/IPMCheckOut";
import PMButton from "../../../themeComponents/PMButton";
import customToast from "../../Toast/CustomToast";
import IForceType from "./../../../../Interfaces/IForceType";
import { UserContext } from "../../../../context/UserContext/userContext";
import {
  clearWeapons,
  resetBracelets,
} from "../../../../redux/actions/orbatTreeActions";
import { useDispatch } from "react-redux";
import AdminPasswordModal from "../../../Desktop/AdminPassword/AdminPasswordModal";
import { checkPassword } from "../../../../services/passwordsHandler";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import IIsDuplicatedForce from "../../../../Interfaces/IIsDuplicatedForce";
import { useWeapons } from "../../../CustomHooks/HooksWithRQ/useWeapons";
import { useForceTypes } from "../../../CustomHooks/HooksWithRQ/useForcesTypes";
import IWeapon from "../../../../Interfaces/IWeapon";
import { exportOrbat } from "../../../../services/orbatExportsFunction";
import EEnvironment from "../../../../Enums/EEnvironment";
import PMLabel from "../../../themeComponents/PMLabel";
import { getCurrentDate } from "../../../../services/createXML";
import PMDropdown from "../../PMDropdown/PMDropdown";

interface IProps {
  checkedForce: IForceTreeNode;
  onChange: (field: string, value: string | boolean | IForceType) => void;
  isAdmin: boolean;
  disableEditing?: boolean;
  isDuplicated: IIsDuplicatedForce | undefined;
}

const ForceDetails: React.FC<IProps> = (props) => {
  const { checkedForce, disableEditing, isDuplicated } = props;
  const { t } = useTranslation();
  const { isAdmin, isInstructor } = useContext(UserContext);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isResetBracelets, setIsResetBracelets] = useState<boolean>(false);
  const [isClearWeapons, setIsClearWeapons] = useState<boolean>(false);
  const [isToCheckout, setIsToCheckout] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(false);
  const [selectedWeapon, setSelectedWeapon] = useState<IWeapon | undefined>();
  const dispatch = useDispatch<Dispatch<any>>();
  const [isLoadingXML, setIsLoadingXML] = useState<boolean>();

  //Hooks
  const { weapons } = useWeapons();
  const { forceTypes } = useForceTypes();

  useEffect(() => {
    setIsDisabled(
      (!checkedForce.id && (!isAdmin || !isInstructor)) || disableEditing
    );
  }, [checkedForce, isAdmin, isInstructor, disableEditing]);

  useEffect(() => {
    setSelectedWeapon(
      weapons.find((weapon) => weapon.name === checkedForce.weapon_type)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedForce.weapon_type, weapons]);

  const onCheckout = async () => {
    if (checkedForce !== undefined) {
      const pmCheckOut: IPMCheckOut = {
        MessageType: EEventType.PM_CHECKOUT,
        SoldierID: checkedForce.soldier_id,
        LaneID: null,
        TimeStamp: new Date(),
        ForceId: checkedForce.id,
      };

      axios
        .post(`${baseUrlPMBackend}checkInOutOperation/PMCheckout`, pmCheckOut)
        .then((res) => {
          customToast.success(`${t("checkoutSucceed")} ${checkedForce.name}`);
        })
        .catch((error) => {
          customToast.error(
            `${t("checkoutFailed")} ${t("for")} ${checkedForce.name}`
          );
          console.error(error);
        });
    }
  };
  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkPassword(password, setInvalid);
    if (isPasswordValid) {
      if (isResetBracelets) {
        setIsResetBracelets(false);
        onResetBracelets();
      }
      if (isToCheckout) {
        setIsToCheckout(false);
        onCheckout();
      }
      if (isClearWeapons) {
        setIsClearWeapons(false);
        onClearWeapons();
      }
    }
  };
  const onClearWeapons = () => {
    dispatch(clearWeapons(checkedForce, customToast, t("clearWeaponsError")));
  };
  const onResetBracelets = () => {
    dispatch(resetBracelets(checkedForce, customToast, t("updateError")));
  };

  const handleDownloadClick = async () => {
    setIsLoadingXML(true);
    try {
      const response = await axios.get(
        `${baseUrlPMBackend}bazXmlGenerator/generateXmlFile`,
        {
          params: { forceId: checkedForce.id },
          responseType: "blob",
        }
      );

      // Create a Blob object containing the XML content
      const blob = new Blob([response.data], { type: "application/xml" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      let date = getCurrentDate();

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `${
        checkedForce
          ? getForceFullName(checkedForce?.name, checkedForce?.force_type, t)
          : ""
      } ${date}.xml`; // Set the filename for download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsLoadingXML(false);
    } catch (error) {
      setIsLoadingXML(false);

      console.error("Error downloading XML file:", error);
    }
  };
  return (
    <>
      <div className="colForceDetails">
        <div className="detailsRowsContainer">
          <PMLabel
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            cssClass="titleLabelFD"
          >
            {t("personalInfo")}
          </PMLabel>
          <div className="details-form">
            <IonRow>
              <IonCol>
                <PMDropdown
                  placeholder={t("ForceType")}
                  options={forceTypes.map((type: IForceType) => {
                    return { value: t(type.name), key: type };
                  })}
                  selectedOption={
                    checkedForce.force_type !== "None"
                      ? {
                          value: t(checkedForce.force_type),
                          key: checkedForce.force_type,
                        }
                      : undefined
                  }
                  onOptionChanges={(e) => {
                    if (instanceOfIForceType(e?.key)) {
                      e?.key && props.onChange("force_type", e?.key);
                    }
                  }}
                  disabled={isDisabled}
                  isSmall
                ></PMDropdown>
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={isDisabled}
                  inputValue={checkedForce.name}
                  inputName="name"
                  placeholder={t("name")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                />
              </IonCol>
              <IonCol>
                <PMInput
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.soldier_id
                      ? "tpInputError"
                      : undefined
                  }
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputValue={
                    checkedForce.soldier_id && checkedForce.soldier_id
                  }
                  inputName="soldier_id"
                  placeholder={t("soldierId")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                />
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputValue={checkedForce.tag_id && checkedForce.tag_id}
                  inputName="tag_id"
                  placeholder={t("tagId")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.tag_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={isDisabled}
                  inputValue={
                    checkedForce.personal_id && checkedForce.personal_id
                  }
                  inputName="personal_id"
                  placeholder={t("personalId")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                  errorCss={
                    isDuplicated?.isDuplicatedSoldier.personal_id ||
                    isDuplicated?.isDuplicatedGroup.personal_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
            </IonRow>
          </div>
          <PMLabel
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            cssClass="titleLabelFD"
          >
            {t("weaponDetails")}
          </PMLabel>
          <div className="details-form">
            <IonRow>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputName="weapon_id"
                  placeholder={t("weaponId")}
                  inputType="text"
                  cssClass={"weaponIdLTROnly"}
                  inputValue={checkedForce.weapon_id}
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.weapon_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
              <IonCol>
                <PMDropdown
                  placeholder={t("simulatedWeaponType")}
                  options={weapons.map((weapon: IWeapon) => {
                    return {
                      key: weapon.name,
                      value: weapon.name,
                    };
                  })}
                  selectedOption={
                    checkedForce.weapon_type !== "None"
                      ? {
                          value: t(checkedForce.weapon_type),
                          key: String(checkedForce.weapon_type),
                        }
                      : undefined
                  }
                  onOptionChanges={(e) => {
                    props.onChange("weapon_type", e?.key ? e.key : "");
                    props.onChange("weapon_sight", "");
                  }}
                  disabled={!checkedForce.is_soldier || isDisabled}
                  isSmall
                ></PMDropdown>
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputValue={checkedForce.weapon_sight_id}
                  inputName="weapon_sight_id"
                  placeholder={t("weaponSightID")}
                  inputType="text"
                  cssClass={"weaponIdLTROnly"}
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                />
              </IonCol>
              <IonCol>
                <div key={checkedForce.weapon_type}>
                  <PMDropdown
                    key={checkedForce.weapon_type}
                    placeholder={t("weaponSightType")}
                    options={
                      selectedWeapon?.sights
                        ? selectedWeapon.sights.map((sight: string) => {
                            return { key: sight, value: sight };
                          })
                        : []
                    }
                    selectedOption={
                      checkedForce.weapon_sight !== "None"
                        ? {
                            value: t(checkedForce.weapon_sight),
                            key: String(checkedForce.weapon_sight),
                          }
                        : undefined
                    }
                    onOptionChanges={(e) => {
                      props.onChange("weapon_sight", e?.key ? e.key : "");
                    }}
                    disabled={!checkedForce.is_soldier || isDisabled}
                    isSmall
                  ></PMDropdown>
                </div>
              </IonCol>
            </IonRow>
          </div>
          <PMLabel
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            cssClass="titleLabelFD"
          >
            {t("magazineDetails")}
          </PMLabel>
          <div className="details-form">
            <IonRow>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputName="magazine_id"
                  placeholder={t("magazineId")}
                  inputType="text"
                  cssClass={"weaponIdLTROnly"}
                  inputValue={
                    checkedForce.magazine_id && checkedForce.magazine_id
                  }
                  onChangeHandler={props.onChange}
                  width="short"
                  height="settingsHeight"
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.magazine_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputValue={checkedForce.laser_id && checkedForce.laser_id}
                  inputName="laser_id"
                  placeholder={t("laserId")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  cssClass={"weaponIdLTROnly"}
                  height="settingsHeight"
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.laser_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
              <IonCol>
                <PMInput
                  isDisabled={!checkedForce.is_soldier || isDisabled}
                  inputValue={
                    checkedForce.head_sensor_id && checkedForce.head_sensor_id
                  }
                  inputName="head_sensor_id"
                  placeholder={t("headSensorId")}
                  inputType="text"
                  onChangeHandler={props.onChange}
                  width="short"
                  cssClass={"weaponIdLTROnly"}
                  height="settingsHeight"
                  errorCss={
                    checkedForce.is_soldier &&
                    isDuplicated?.isDuplicatedSoldier.head_sensor_id
                      ? "tpInputError"
                      : undefined
                  }
                />
              </IonCol>
            </IonRow>
          </div>
          <div className="buttonsWrap">
            {isAdmin && checkedForce.id ? (
              <>
                <PMButton
                  size="large"
                  color="orange"
                  fill="outline"
                  label={{
                    fontColor: "light",
                    fontSize: "small",
                    fontFamily: "Light",
                    textAlign: "center",
                  }}
                  onClickHandler={() =>
                    checkedForce.nodes?.length
                      ? setIsToCheckout(true)
                      : onCheckout()
                  }
                  isDisabled={isDisabled}
                  cssClass="forceDetailsButton"
                >
                  {t("checkout")}
                </PMButton>
                {checkedForce.nodes !== null ? (
                  <PMButton
                    size="large"
                    color="orange"
                    fill="outline"
                    label={{
                      fontColor: "light",
                      fontSize: "small",
                      fontFamily: "Light",
                      textAlign: "center",
                    }}
                    onClickHandler={() => setIsResetBracelets(true)}
                    isDisabled={isDisabled}
                    cssClass="forceDetailsButton"
                  >
                    {t("resetBracelets")}
                  </PMButton>
                ) : null}
                {environment === EEnvironment.staging ? (
                  <PMButton
                    size="large"
                    color="orange"
                    fill="outline"
                    label={{
                      fontColor: "light",
                      fontSize: "small",
                      fontFamily: "Light",
                      textAlign: "center",
                    }}
                    onClickHandler={() => setIsClearWeapons(true)}
                    isDisabled={isDisabled}
                    cssClass="forceDetailsButton"
                  >
                    {t("clearWeapons")}
                  </PMButton>
                ) : null}
                {environment === EEnvironment.staging ? (
                  <PMButton
                    size="large"
                    color="orange"
                    fill="outline"
                    label={{
                      fontColor: "light",
                      fontSize: "small",
                      fontFamily: "Light",
                      textAlign: "center",
                    }}
                    onClickHandler={handleDownloadClick}
                    isDisabled={
                      checkedForce.nodes === null || isDisabled || isLoadingXML
                    }
                    cssClass="forceDetailsButton"
                  >
                    {isLoadingXML ? (
                      <IonSpinner className="xmlSpinner"></IonSpinner>
                    ) : null}
                    {t("createXMLForBaz")}
                  </PMButton>
                ) : null}
              </>
            ) : null}
            {checkedForce.id &&
            (isInstructor || isAdmin) &&
            environment !== EEnvironment.production ? (
              <PMButton
                size="large"
                color="orange"
                fill="outline"
                label={{
                  fontColor: "light",
                  fontSize: "small",
                  fontFamily: "Light",
                  textAlign: "center",
                }}
                onClickHandler={() => exportOrbat(checkedForce, t)}
                cssClass="forceDetailsButton"
              >
                {t("Export ORBAT")}
              </PMButton>
            ) : null}
          </div>
          <AdminPasswordModal
            onSendPasswordHandler={(password: string) =>
              onSendPasswordHandler(password)
            }
            onCancelHandler={() => {
              setIsResetBracelets(false);
              setIsToCheckout(false);
              setIsClearWeapons(false);
            }}
            invalid={invalid}
            text={
              isResetBracelets
                ? `${t("enterPassword")} ${t("resetBraceletsQuestion")}`
                : isClearWeapons
                ? `${t("enterPassword")} ${t("clearWeaponsQuestion")}`
                : `${t("enterPassword")} ${t("checkoutQuestion")}`
            }
            isOpen={isResetBracelets || isToCheckout || isClearWeapons}
          ></AdminPasswordModal>
        </div>
      </div>
    </>
  );
};

export default ForceDetails;
